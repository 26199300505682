import React, { useState } from "react";
import { Checkbox, Menu, MenuItem, IconButton, Tooltip } from "@mui/material";
import { FaFilter, FaSort, FaAngleUp, FaAngleDown } from "react-icons/fa";
import { FilterType } from "./index";
import "./index.scss";
type FilterOption = {
  value: string | number;
  label: string;
};
type FilterableHeaderProps = {
  filterVisibility: boolean;
  handleClearFilter: (filterKey: FilterType) => void;
  handleFilterValueChange: (
    filterKey: FilterType,
    value: string | number
  ) => void;
  filterOptions: (FilterOption | number)[];
  selectedFilters?: (string | number)[];
  filterKey: FilterType;
  isActive: boolean;
  selectedFilter?: string | number;
  tooltipContent: React.ReactNode;
  onSortChange?: () => void;
  sortDirection?: "ascending" | "descending" | "none";
};

const FilterableHeader: React.FC<FilterableHeaderProps> = ({
  handleClearFilter,
  handleFilterValueChange,
  filterOptions,
  selectedFilters,
  filterKey,
  isActive,
  selectedFilter,
  onSortChange,
  sortDirection = "none",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  if (filterKey === "PlannedRevenue") {
    filterOptions = filterOptions.filter((option) => option !== 0);
  }

  const handleClearFilters = (filterKey: FilterType) => {
    handleClearFilter(filterKey);
    handleCloseMenu();
  };

  const handleSelectFilters = (
    filterKey: FilterType,
    option: FilterOption | number
  ) => {
    if (typeof option === "object") {
      handleFilterValueChange(filterKey, option.value);
    } else {
      handleFilterValueChange(filterKey, option);
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpenMenu}
        style={{
          color: isActive ? "orange" : "inherit",
          width: "15px",
          height: "15px",
          padding: "0px",
        }}
      >
        {filterKey != "sprintFilter" ? (
          <FaFilter />
        ) : (
          <FaAngleDown
            style={{
              marginLeft: "5px",
              cursor: "pointer",
              fontSize: "13px",
            }}
          />
        )}
      </IconButton>

      {onSortChange && (
        <span style={{ cursor: "pointer" }} onClick={onSortChange}>
          {sortDirection === "ascending" && <FaAngleUp />}
          {sortDirection === "descending" && <FaAngleDown />}
          {sortDirection === "none" && <FaSort />}
        </span>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && menuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          maxHeight: "400px",
        }}
      >
        <MenuItem
          onClick={() => handleClearFilters(filterKey)}
          style={{ color: "red", justifyContent: "center" }}
        >
          Clear Filters
        </MenuItem>
        {filterOptions.length > 0 ? (
          filterOptions.map((option) => {
            const isMultipleSelected = selectedFilters && selectedFilters?.includes(
              option && typeof option === "object" ? option.value : option
            ) 
            const isSingleSelected = selectedFilter === (typeof option === "object" ? option.value : option);

            const isFiltered = isMultipleSelected || isSingleSelected;

            return (
              <MenuItem
                key={typeof option === "object" ? option.value : option}
                selected={isMultipleSelected}
                onClick={() => handleSelectFilters(filterKey, option)}
                className={isFiltered ? "selected" : "not-selected"}
              >
                {filterKey !== "sprintFilter" && (
                  <Checkbox
                  sx={{
                    padding:"0px !important"
                  }}
                    checked={isMultipleSelected}
                  />
                )}
                {option && !Array.isArray(option) && typeof option === "object" 
                  ? option.label
                  : option.toString()}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem disabled>No options found</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default FilterableHeader;
