import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Project,
  ProjectRespData,
  ProjectRespDataInternal,
  getAllProjects,
  getProjects,
  groupProject,
  getProjectsByInternalSync
} from "common/api/ApiService";
import "./index.scss";
import TableBodys from "./TableBody";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "components/DashboardHeader";
import {
  FaAngleDown,
  FaAngleUp,
  FaEdit,
  FaFilter,
  FaPlus,
  FaRegObjectUngroup,
  FaSort,
} from "react-icons/fa";
import { IoArrowBack, IoCloseCircleOutline } from "react-icons/io5";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  MenuItem,
  Modal,
  TableBody,
  TextField,
  Tooltip,
} from "@mui/material";
import YourComponent from "./ToolTips";
import BillingStatus from "./StatusTips";
import Progress from "./ToolTipp";
import Satisfaction from "./TeamSatisfaction";
import ToolTipComponent from "./ToolTips";
import { useProjects } from "reducers/GlobalState";
import FilterableHeader from "./FilterTopUp";

const tooltipStyles = {
  tooltip: {
    backgroundColor: "white", // Background color
    color: "#333", // Text color
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", // Shadow
    fontSize: "10px",
  },
};
export type FilterType =
| "Criticality"
| "Department"
| "Status"
| "ProjectType"
| "PlannedEBITDA"
| "ActualEBITDA"
| "TargetEBITDA"
| "PlannedRevenue"
| "ProcessAdherence"
| "sprintFilter";
export const departmentOptions = [
  // { label: "Clear", value: 0 },
  { label: "Design", value: 1 },
  { label: "Development", value: 2 },
  { label: "Multi", value: 3 },
];

const criticalityOptions = [
  // { label: "Clear", value: "0" },
  { label: "Platinum", value: "Platinum" },
  { label: "Diamond", value: "Diamond" },
  { label: "Premium", value: "Premium" },
];

const projectAdherenceOptions = [
  // { label: "Clear", value: 0 },
  { label: "> 85 & <= 100", value: 1 },
  { label: "> 70 & <= 85", value: 2 },
  { label: "> 50 & <= 70", value: 3 },
  { label: "> 25 & <= 50", value: 4 },
  { label: "> 0 & <= 25", value: 5 },
  { label: "0", value: 6 },
];

const projectSprintOptions = [
  { label: "Clear", value: 0 },
  { label: "Active Sprint", value: 1 },
  { label: "Last Sprint", value: 2 },
  { label : "All Sprints", value: 'aggregate' }
]
const projectStatusOptions = [
  // { label: "Clear", value: 0 },
  { label: "Not started", value: 1 },
  { label: "In progress", value: 2 },
  { label: "Hold", value: 3 },
  { label: "Closed", value: 4 },
];
const projectTypeOptions = [
  // { label: "Clear", value: 0 },
  { label: "T&M", value: 1 },
  { label: "Fixed", value: 2 },
  { label: "T&M(Cap)", value: 3 },
  { label: "Retainer", value: 4 },
];
interface Props {
  selectedProjectId: string;
  setSelectedProjectId: (projectId: string) => void;
  isEdit: boolean;
  setIsEdit: (isEdit: boolean) => void;
  isSuccessEdit: boolean;
  setIsSuccessEdit: (isSuccessEdit: boolean) => void;
}
interface FilterSelectedOptions {
    projectType: number[];
    projectCriticality: string[];
    department: number[];
    projectStatus: number[];
    plannedRevenue: number[];
    processAdherence: number[];
}
interface UpdateFiltersArgs {
  filterKey: string;
  value: number | string | null;
}

const InternalDashboardTable: React.FC<Props> = ({
  selectedProjectId,
  setSelectedProjectId,
  isEdit,
  setIsEdit,
  isSuccessEdit,
  setIsSuccessEdit,
}) => {
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const token = sessionStorage.getItem("authToken");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedSubProject, setSelectedSubProject] = useState<Project[]>([]);
  const [dropdownProjects, setDropdownProjects] = useState<Project[]>([]);
  const [projects, setProjects] = useState<ProjectRespDataInternal[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterType | null>(null);
  const [expandedProjectId, setExpandedProjectId] = useState<number | null>(
    null
  );
  const [filterProjects, setFilterProjects] = useState<
    ProjectRespDataInternal[]
  >([]);
  const [selectedCriticality, setSelectedCriticality] = useState<string | null>(
    null
  );

  const [selectedDepartment, setSelectedDepartment] = useState<number | null>(
    null
  );

  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
  const [activeFilters, setActiveFilters] = useState<boolean | null>(false);
  // const [activeSort,setActiveSort]=useState(false)
  const [plannedEbitdaFilter, setPlannedEbitdaFilter] = useState<number[]>([]);
  const [actualEbitdaFilter, setActualEbitdaFilter] = useState<number[]>([]);
  const [targetEbitdaFilter, setTargetEbitdaFilter] = useState<number[]>([]);
  const [plannedRevenueFilter, setPlannedRevenueFilter] = useState<
    number | null
  >(null);
  const [selectedProcessAdherence, setSelectedProcessAdherence] = useState<
    number | null
  >(null);
  const [selectedDashboardFilters,setSelectedDashboardFilters]=useState<FilterSelectedOptions>({
    projectType:[],
    projectCriticality:[],
    department:[],
    projectStatus:[],
    plannedRevenue:[],
    processAdherence:[],
  })

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedProjectType, setSelectedProjectType] = useState<number | null>(
    null
  );
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [filterVisible, setFilterVisible] = useState(false);

  const filterRefs = Array.from({ length: 14 }, () =>
    useRef<HTMLDivElement | null>(null)
  );

  const [appliedFilters, setAppliedFilters] = useState<{
    [key in FilterType]?: boolean;
  }>({});

  const [sprintFilter, setSprintFilter] = useState<number>(1);

  const location  = useLocation();

  useEffect(() => {
    if (token) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, [token]);

  const projectsContext = useProjects();
  if (!projectsContext) {
    throw new Error("useProjects must be used within a ProjectsProvider");
  }
  const { contextData, setContextData } = projectsContext;

  const fetchProjects = async () => {
    try {
      if(location.state?.isCancelClicked){
        setProjects(contextData);
        setFilterProjects(contextData);
      }
      else{
        setLoading(true);
        const data = await getProjects(sprintFilter);
        setProjects(data);
        setFilterProjects(data);
        setContextData(data);
        if (isSuccessEdit || isEdit) {
          setIsEdit(false);
          setIsSuccessEdit(false);
        }
      }    
    } catch (error) {
      showAlertMessage("error", "Error while fetching projects");
      console.error("Error fetching projects:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [sprintFilter]);

  useEffect(() => {
    const fetchProjectList = async () => {
      try {
        // setLoading(true);
        const data = await getAllProjects(1, [1, 2], 0);
        setDropdownProjects(data);
      } catch (error) {
        showAlertMessage("error", "Error while fetching project data");
        console.error("Error while fetching project data", error);
      }
    };
    fetchProjectList();
  }, []);

  const handleFilterClick = () => {
    setFilterVisible(!filterVisible);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterRefs.every(
        (ref) => ref.current && !ref.current.contains(event.target as Node)
      )
    ) {
      setSelectedFilter(null);
      toggleFilter("null");
      setFilterVisible(false);
    }
  };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);



  interface FilterVisibility {
    Criticality: boolean;
    Department: boolean;
    Status: boolean;
    ProjectType: boolean;
    PlannedEBITDA: boolean;
    ActualEBITDA: boolean;
    TargetEBITDA: boolean;
    PlannedRevenue: boolean;
    ProcessAdherence: boolean;
    sprintFilter: boolean;
  }

  const [filterVisibility, setFilterVisibility] = useState<FilterVisibility>({
    Criticality: false,
    Department: false,
    Status: false,
    ProjectType: false,
    PlannedEBITDA: false,
    ActualEBITDA: false,
    TargetEBITDA: false,
    PlannedRevenue: false,
    ProcessAdherence: false,
    sprintFilter: false,
  });

  const handleFilterChange = (selectedOption: FilterType) => {
    if (selectedFilter === selectedOption) {
      setSelectedFilter(null);
      toggleFilter("null");
    } else {
      setSelectedFilter(selectedOption);

      toggleFilter(selectedOption);
    }
  };

  const toggleFilter = (filterType: FilterType | "null") => {
    // console.log(filterType);
    setFilterVisibility((prevState) => ({
      Criticality: false,
      Department: false,
      ProjectType: false,
      Status: false,
      PlannedEBITDA: false,
      ActualEBITDA: false,
      TargetEBITDA: false,
      PlannedRevenue: false,
      ProcessAdherence: false,
      sprintFilter: false,
      [filterType]:
        filterType !== "null" && !prevState[filterType as FilterType],
    }));
  };
  let selectedDashboardProjectStatusFilter:number[] = [...selectedDashboardFilters.projectStatus];
  let selectedDashboardProjectTypeFilter:number[] = [...selectedDashboardFilters.projectType];
  let selectedDashboardCriticalityFilters:string[] = [...selectedDashboardFilters.projectCriticality];
  let selectedDashboardDepartmentFilters:number[] = [...selectedDashboardFilters.department];
  let selectedDashboardPlannedRevenueFilter:number[] = [...selectedDashboardFilters.plannedRevenue];
  let selectedDashboardProcessAdherenceFilter:number[] = [...selectedDashboardFilters.processAdherence];  

  const handleFilterValueChange = (filterType: FilterType, value: any) => {
    let updatedPlannedEbitdaFilter = [...plannedEbitdaFilter];
    let updatedActualEbitdaFilter = [...actualEbitdaFilter];
    let updatedTargetEbitdaFilter = [...targetEbitdaFilter];
    let updatedSprintFilter = sprintFilter;
   
    const handleFilterUpdate = (
      value: string | number,
      currentFilters: any,
      setFilters: any,
      filterKey: string,
      localFilterArray: any[],
    ) => {
      let filterValue = value === "0" || value === 0 ? null : value;

      if (filterValue) {
        if (currentFilters[filterKey].includes(filterValue)) {
          localFilterArray = currentFilters[filterKey].filter((id: any) => id !== filterValue);
          setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterKey]: localFilterArray,
          }));
        } else {
          localFilterArray = [...currentFilters[filterKey], filterValue];
          setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterKey]: filterValue ? [...prevFilters[filterKey], filterValue] : [],
          }));
        }
      } else {
        localFilterArray = [];
        setFilters((prevFilters: any) => ({
          ...prevFilters,
          [filterKey]: [],
        }));
      }

      return localFilterArray

    };

    switch (filterType) {
      case "Criticality":
        selectedDashboardCriticalityFilters = handleFilterUpdate(
          value,
          selectedDashboardFilters,
          setSelectedDashboardFilters,
          "projectCriticality",
          selectedDashboardCriticalityFilters,
        );
        break;
      case "ProjectType":
        selectedDashboardProjectTypeFilter = handleFilterUpdate(
          value,
          selectedDashboardFilters,
          setSelectedDashboardFilters,
          "projectType",
          selectedDashboardProjectTypeFilter,
        );
        break;
      case "Department":
        selectedDashboardDepartmentFilters = handleFilterUpdate(
          value,
          selectedDashboardFilters,
          setSelectedDashboardFilters,
          "department",
          selectedDashboardDepartmentFilters,
        );
        break;
      case "Status":
        selectedDashboardProjectStatusFilter = handleFilterUpdate(
          value,
          selectedDashboardFilters,
          setSelectedDashboardFilters,
          "projectStatus",
          selectedDashboardProjectStatusFilter,
        );
        break;
      case "PlannedEBITDA":
        updatedPlannedEbitdaFilter =
          value === 0
            ? []
            : toggleValueInArray(updatedPlannedEbitdaFilter, value);
        setPlannedEbitdaFilter(updatedPlannedEbitdaFilter);
        break;
      case "ActualEBITDA":
        updatedActualEbitdaFilter =
          value === 0
            ? []
            : toggleValueInArray(updatedActualEbitdaFilter, value);
        setActualEbitdaFilter(updatedActualEbitdaFilter);
        break;
      case "TargetEBITDA":
        updatedTargetEbitdaFilter =
          value === 0
            ? []
            : toggleValueInArray(updatedTargetEbitdaFilter, value);
        setTargetEbitdaFilter(updatedTargetEbitdaFilter);
        break;
      case "PlannedRevenue":
        let plannedRevenue =
          value === 0 ? null : isNaN(value) ? null : value;
        if(plannedRevenue){
          if(selectedDashboardFilters.plannedRevenue.includes(plannedRevenue)){
              selectedDashboardPlannedRevenueFilter = selectedDashboardFilters.plannedRevenue.filter((revenue) => revenue !== plannedRevenue);
              setSelectedDashboardFilters((prevFilters) => ({ 
                  ...prevFilters,
                  plannedRevenue: selectedDashboardPlannedRevenueFilter,
           }
         ))
          }
          else{
            selectedDashboardPlannedRevenueFilter =  [...selectedDashboardFilters.plannedRevenue ,plannedRevenue];
            setSelectedDashboardFilters((prevFilters) => ({ 
              ...prevFilters,
              plannedRevenue: plannedRevenue ? [...prevFilters.plannedRevenue,plannedRevenue] : [],
            }
            ))
          }
        }
        else {
            selectedDashboardPlannedRevenueFilter = [];
            setSelectedDashboardFilters((prevFilters) => ({
              ...prevFilters,
              plannedRevenue: [],
            }
            ))
        }
        break;
      case "ProcessAdherence":    
        selectedDashboardProcessAdherenceFilter = handleFilterUpdate(
          value,
          selectedDashboardFilters,
          setSelectedDashboardFilters,
          "processAdherence",
          selectedDashboardProcessAdherenceFilter,
        );
        break;
      case "sprintFilter":
        updatedSprintFilter = value === 0 ? 1 : value;
        setSprintFilter(updatedSprintFilter);
        handleFilterChange("sprintFilter");
        break;
      default:
        break;
    }

    setAppliedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value !== 0,
    }));

    // Use updatedCriticality directly in the filter logic
    const filterProjectWithSubProjects = (project: ProjectRespDataInternal) => {
      // Check main project
      let passesFilters = true;

      if(selectedDashboardCriticalityFilters.length > 0 &&
         !selectedDashboardCriticalityFilters.includes(project.criticality)) {
            passesFilters = false;
      }
      if(selectedDashboardDepartmentFilters.length > 0 && 
        !selectedDashboardDepartmentFilters.includes(project.departmentId)) {
          passesFilters = false;
        }
      if(
        selectedDashboardProjectTypeFilter.length > 0 && 
        !selectedDashboardProjectTypeFilter.includes(project.projectType.id)) {
          passesFilters = false;
      }
      if (
        selectedDashboardProjectStatusFilter.length > 0 &&
        !selectedDashboardProjectStatusFilter.includes(project.projectStatusId)
      ) {
        passesFilters = false;
      }
      if (updatedPlannedEbitdaFilter.length > 0 &&
        !updatedPlannedEbitdaFilter.includes(project.plannedEbitda)) {
        passesFilters = false;
      }
      if(selectedDashboardPlannedRevenueFilter.length > 0 &&
        !selectedDashboardPlannedRevenueFilter.includes(project.totalQuote)) {
          passesFilters = false;
        }
      
      if (updatedActualEbitdaFilter.length > 0 &&
        !updatedActualEbitdaFilter.includes(project.actualEbitda)) {
        passesFilters = false;
      }
      if (updatedTargetEbitdaFilter.length > 0 &&
        !updatedTargetEbitdaFilter.includes(project.targetEbitda)) {
        passesFilters = false;
      }

      // Process adherence filter logic for main project
      if (selectedDashboardProcessAdherenceFilter.length > 0) {
        const adherenceValue = project?.processAdherence || 0;
        let passesAdherenceFilters = false;
        for (let i = 0; i < selectedDashboardProcessAdherenceFilter.length; i++) {
          const filterValue = selectedDashboardProcessAdherenceFilter[i];
          switch (filterValue) {
        case 1:
          if (adherenceValue > 85 && adherenceValue <= 100) passesAdherenceFilters = true;
          break;
        case 2:
          if (adherenceValue > 70 && adherenceValue <= 85) passesAdherenceFilters = true;
          break;
        case 3:
          if (adherenceValue > 50 && adherenceValue <= 70) passesAdherenceFilters = true;
          break;
        case 4:
          if (adherenceValue > 25 && adherenceValue <= 50) passesAdherenceFilters = true;
          break;
        case 5:
          if (adherenceValue > 0 && adherenceValue <= 25) passesAdherenceFilters = true;
          break;
        case 6:
          if (adherenceValue === 0) passesAdherenceFilters = true;
          break;
        default:
          break;
          }
          if (passesAdherenceFilters) {
        break;
          }
        }
        if (!passesAdherenceFilters) {
          passesFilters = false;
        }
      }

      // If the main project doesn't pass the filters, check subprojects
      if (!passesFilters && project.subProjects) {
        passesFilters = project.subProjects.some(subProject =>
          filterProjectWithSubProjects(subProject)
        );
      }
      return passesFilters;
    };

    // Use this updated filtering function
    const filtered =  projects?.filter((project) => filterProjectWithSubProjects(project));
    setFilterProjects(filtered);
    setActiveFilters(true);
  };

  const toggleValueInArray = (array: any[], value: any) => {
    return array.includes(value)
      ? array.filter((v) => v !== value)
      : [...array, value];
  };

  // this function helps in ediitng that particular project
  const handleEditClick = (projectId: string) => {
    setSelectedProjectId(projectId);
    setIsEdit(true);
    navigate("/project-iq/createform");
  };

  // this function helps us to expand the subproject if there on clicking project name
  const handleProjectNameClick = (projectId: number) => {
    setExpandedProjectId(expandedProjectId === projectId ? null : projectId);
  };

  const handleCreateForm = () => {
    setIsEdit(false);
    setSelectedProjectId("0");
    navigate("/project-iq/createform");
  };
  const [sortDirectionPlannedRevenue, setSortDirectionPlannedRevenue] =
    useState<"ascending" | "descending" | "none">("none");
  const [sortDirectionPlannedEBITDA, setSortDirectionPlannedEBITDA] = useState<
    "ascending" | "descending" | "none"
  >("ascending");
  const [sortDirectionTargetEBITDA, setSortDirectionTargetEBITDA] = useState<
    "ascending" | "descending" | "none"
  >("ascending");
  const [sortDirectionActualEBITDA, setSortDirectionActualEBITDA] = useState<
    "ascending" | "descending" | "none"
  >("ascending");

  // Function to toggle sort direction for the general sort

  // Function to handle sorting by a specified field

  const handleScroll = () => {
    setSelectedFilter(null);
    toggleFilter("null");
  };

  const handleSave = async () => {
    // console.log(groupName);
    const savedProjects = selectedSubProject.map((project) => ({
      projectId: project.project_id,
    }));

    const payload: any = {
      name: groupName,
      subProjects: savedProjects,
    };
    try {
      if (!groupName) {
        showAlertMessage("error", "Required Project Name");
        return;
      }
      if (savedProjects.length < 2) {
        showAlertMessage("error", "Required Min 2 Projects to Create Group");
        return;
      }
      // console.log(JSON.stringify(payload));
      const resp = await groupProject(payload);
      // const resp = true;
      if (resp) {
        showAlertMessage("success", "Grouped Project Successfully");
        setIsSuccessEdit(true);
        setTimeout(() => {
          handleCancel();
          setIsModalOpen(false);
        }, 2000);
      } else showAlertMessage("error", "Error While Grouping Project");
      // console.log("response", resp);
    } catch (error) {
      console.error("error while creating sub projects");
    }
  };
  const watchActiveFilters = Object.values(appliedFilters).some(
    (filter) => filter === true
  );
  const handleCancel = () => {
    setIsModalOpen(false);
    setGroupName("");
    setSelectedSubProject([]);
  };

  const clearAllFilters = () => {
    setSelectedCriticality(null);
    setSelectedDepartment(null);
    setSelectedStatus(null);
    setSelectedProjectType(null);
    setPlannedEbitdaFilter([]);
    setActualEbitdaFilter([]);
    setTargetEbitdaFilter([]);
    setPlannedRevenueFilter(null);
    setAppliedFilters({});
    setFilterProjects(projects); // Reset to show all projects
    setActiveFilters(false);
  };

  const handleSort = (
    field: keyof ProjectRespDataInternal,
    columnSortDirection: "ascending" | "descending" | "none",
    setColumnSortDirection: React.Dispatch<
      React.SetStateAction<"ascending" | "descending" | "none">
    >
  ) => {
    // Toggle sort direction for the current column
    setSelectedFilter(null);
    if (field == "actualEbitda") {
      setSortDirectionPlannedEBITDA("ascending");
      setSortDirectionTargetEBITDA("ascending");
    } else if (field == "targetEbitda") {
      setSortDirectionPlannedEBITDA("ascending");
      setSortDirectionActualEBITDA("ascending");
    } else if (selectedFilter !== null) {
      setSortDirectionPlannedEBITDA("ascending");
      setSortDirectionTargetEBITDA("ascending");
      setSortDirectionActualEBITDA("ascending");
    }
    else {
      setSortDirectionTargetEBITDA("ascending");
      setSortDirectionActualEBITDA("ascending");
    }
    let direction: "ascending" | "descending" | "none";
    if (columnSortDirection === "none") {
      direction = "ascending";
    } else if (columnSortDirection === "ascending") {
      direction = "descending";
    } else {
      direction = "ascending";
    }
    setColumnSortDirection(direction);
    // console.log("here");
    // Sorting logic based on the current column's sort direction
    const sortedProjects = [...filterProjects].sort((a, b) => {
      // Ensure values are converted to numbers if they are strings
      const aValue =
        typeof a[field] === "string"
          ? parseFloat(a[field] as string)
          : (a[field] as number) || 0;
      const bValue =
        typeof b[field] === "string"
          ? parseFloat(b[field] as string)
          : (b[field] as number) || 0;
      if (direction === "ascending") {
        return aValue - bValue;
      } else if (direction === "descending") {
        return bValue - aValue;
      }
      // window.location.reload();
      return 0; // No sorting
    });
    // console.log(sortedProjects);

    // Update state with sorted projects
    setFilterProjects(sortedProjects);

    // console.log(filterProjects);
  };
  const showAlertMessage = (type: "success" | "error", message: string) => {
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
      // setAlertType("");
    }, 2000);
  };
  const handleClearFilter = (filterType: FilterType) => {
    if(filterType === "Criticality"){
      handleFilterValueChange("Criticality","0");
    }
    if(filterType === "Department"){
    handleFilterValueChange("Department",0);
    }
    if(filterType === "Status"){
     handleFilterValueChange("Status",0);
    }
    if(filterType === "ProjectType"){
     handleFilterValueChange("ProjectType",0);
    }
    if(filterType === "PlannedRevenue"){
      handleFilterValueChange("PlannedRevenue",0);
    }
    if(filterType === "ProcessAdherence"){
      handleFilterValueChange("ProcessAdherence",0);
    }
  }
  const handleClearAll = () => {
    clearAllFilters();
    setSelectedDashboardFilters({
      projectType:[],
      projectCriticality:[],
      department:[],
      projectStatus:[],
      plannedRevenue:[],
      processAdherence:[],
    });
  }
  const handleSync = async() => {
    setLoading(true);
    const data = await getProjectsByInternalSync(); 
    if ('statusCode' in data) {
        if(data.statusCode===200){
          fetchProjects();
        }
        else{
          showAlertMessage("error", "Error while syncing projects");
        }
    }
    else{
      showAlertMessage("error", "Error while syncing projects");
    }
  }

  const handleGroupProjects = () => {
     setIsEdit(true);
     setIsModalOpen(true);
     setShowAlert(false);
  };

  const isSelectedDashboardFilters: boolean = useMemo(() => {
    return Object.values(selectedDashboardFilters).some((array) => array.length > 0);
  }, [selectedDashboardFilters]);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <DashboardHeader />
      {isLogged && (
        <div>
          <div className="header-container">
            <div className="header-breadcrumb">
              <span
                onClick={() => navigate("/")}
                className="back-icon-dashboard"
              >
                <IoArrowBack size={24} />
              </span>
              <h1>Internal Dashboard</h1>
            </div>
            <div className="header-buttons-container">
              <button
                style={{
                  visibility: isSelectedDashboardFilters ? "visible" : "hidden",
                }}
                onClick={handleClearAll}
              >
                clear all
              </button>
              <button onClick={handleSync}>Sync Projects</button>
              <Tooltip title="Create Project">
                <button onClick={handleCreateForm}>
                  <FaPlus />
                </button>
              </Tooltip>

              <Tooltip title="Group Projects">
                <button
                  onClick={handleGroupProjects}
                >
                  <FaRegObjectUngroup />
                </button>
              </Tooltip>
            </div>
            <Modal open={isModalOpen} onClose={handleCancel}>
              <Box className="modal-box">
                {showAlert && (
                  <Alert
                    severity={alertType}
                    className="floating-alert"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setShowAlert(false)}
                      >
                        <IoCloseCircleOutline fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {alertMessage}
                  </Alert>
                )}
                <h2>Group Projects</h2>
                <TextField
                  label="Project Name"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Autocomplete
                  multiple
                  options={dropdownProjects}
                  getOptionLabel={(option) => option.name}
                  value={selectedSubProject}
                  onChange={(event, newValue) => {
                    setSelectedSubProject(newValue as Project[]);
                  }}
                  renderTags={(tagValue, getTagProps) => {
                    if (containerRef.current) {
                      (containerRef.current as HTMLDivElement).scrollTop = (
                        containerRef.current as HTMLDivElement
                      ).scrollHeight;
                    }

                    return (
                      <div
                        ref={containerRef}
                        style={{
                          maxHeight: "7em",
                          overflowY: "auto",
                          maxWidth: "100%",
                        }}
                      >
                        {tagValue.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                            key={`${option.project_id}-${index}`}
                          />
                        ))}
                      </div>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Projects"
                      fullWidth
                      margin="normal"
                      // multiline={true}
                      rows={3}
                    />
                  )}
                />

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  // marginTop="20px"
                  position="fixed"
                  bottom="30px"
                  width="90%"
                  padding="10px"
                >
                  <Button
                    onClick={handleCancel}
                    variant="contained"
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Modal>
          </div>
          <div className="table-container">
            <div className="table-wrapper" onScroll={handleScroll}>
              <table>
                {/* table headers */}
                <thead>
                  <tr>
                    <th className="maxi fixed ">Name</th>
                    <th className="maxi">
                      <div className="filter-container">
                        Project Type
                        <FilterableHeader
                          tooltipContent={<div>Filter projects by status</div>}
                          filterVisibility={filterVisibility.ProjectType}
                          handleClearFilter={handleClearFilter}
                          handleFilterValueChange={handleFilterValueChange}
                          filterOptions={projectTypeOptions}
                          selectedFilters={selectedDashboardFilters.projectType}
                          filterKey="ProjectType"
                          isActive={
                            selectedDashboardFilters.projectType.length > 0
                          }
                        />
                      </div>
                    </th>
                    <th className="maxi">
                      <div className="filter-container">
                        Criticality
                        <FilterableHeader
                          tooltipContent={<div>Filter projects by status</div>}
                          filterVisibility={filterVisibility.Criticality}
                          handleClearFilter={handleClearFilter}
                          handleFilterValueChange={handleFilterValueChange}
                          filterOptions={criticalityOptions}
                          selectedFilters={
                            selectedDashboardFilters.projectCriticality
                          }
                          filterKey="Criticality"
                          isActive={
                            selectedDashboardFilters.projectCriticality.length >
                            0
                          }
                        />
                      </div>
                    </th>
                    <th className="maxi">
                      <div className="filter-container">
                        <p>Department</p>
                        <FilterableHeader
                          tooltipContent={<div>Filter projects by status</div>}
                          filterVisibility={filterVisibility.Department}
                          handleClearFilter={handleClearFilter}
                          handleFilterValueChange={handleFilterValueChange}
                          filterOptions={departmentOptions}
                          selectedFilters={selectedDashboardFilters.department}
                          filterKey="Department"
                          isActive={
                            selectedDashboardFilters.department.length > 0
                          }
                        />
                      </div>
                    </th>
                    <th className="maxi">
                      <div className="filter-container">
                        <p>Project Status</p>
                        <FilterableHeader
                          tooltipContent={<div>Filter projects by status</div>}
                          filterVisibility={filterVisibility.Status}
                          handleClearFilter={handleClearFilter}
                          handleFilterValueChange={handleFilterValueChange}
                          filterOptions={projectStatusOptions}
                          selectedFilters={
                            selectedDashboardFilters.projectStatus
                          }
                          filterKey="Status"
                          isActive={
                            selectedDashboardFilters.projectStatus.length > 0
                          }
                        />
                      </div>
                    </th>

                    <th className="maxi-header">
                      Planned Revenue (₹)
                      <FilterableHeader
                        tooltipContent="Sort and filter Planned Revenue"
                        filterVisibility={filterVisibility.PlannedRevenue}
                        handleClearFilter={handleClearFilter}
                        handleFilterValueChange={handleFilterValueChange}
                        filterOptions={[
                          ...Array.from(
                            new Set(
                              projects.map((project) => project.totalQuote)
                            )
                          ),
                        ]}
                        selectedFilters={
                          selectedDashboardFilters.plannedRevenue
                        }
                        filterKey="PlannedRevenue"
                        isActive={
                          selectedDashboardFilters.plannedRevenue.length > 0
                        }
                        onSortChange={() =>
                          handleSort(
                            "totalQuote",
                            sortDirectionPlannedRevenue,
                            setSortDirectionPlannedRevenue
                          )
                        }
                        sortDirection={sortDirectionPlannedRevenue}
                      />
                    </th>
                    <th className="maxi-header">Current Revenue (₹)</th>
                    <th className="maxi-class">
                      <div className="process-adherence-container">
                        <p> Process Adherence (%)</p>
                        <div className="process-adherence-icons-container">
                          <FilterableHeader
                            tooltipContent="Sort and filter Planned Revenue"
                            filterVisibility={filterVisibility.ProcessAdherence}
                            handleClearFilter={handleClearFilter}
                            handleFilterValueChange={handleFilterValueChange}
                            filterOptions={projectAdherenceOptions}
                            selectedFilters={
                              selectedDashboardFilters.processAdherence
                            }
                            filterKey="ProcessAdherence"
                            isActive={
                              selectedDashboardFilters.processAdherence.length >
                              0
                            }
                          />

                          <FilterableHeader
                            tooltipContent="Sort and filter Planned Revenue"
                            filterVisibility={filterVisibility.sprintFilter}
                            handleClearFilter={handleClearFilter}
                            handleFilterValueChange={handleFilterValueChange}
                            filterOptions={projectSprintOptions}
                            filterKey="sprintFilter"
                            isActive={sprintFilter !== 0}
                            selectedFilter={sprintFilter}
                          />
                          {/* <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleFilterChange("sprintFilter")}
                        className={
                          appliedFilters.sprintFilter ? "active-filter" : ""
                        }
                        ref={filterRefs[12]}
                      >
                        <FaAngleDown
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            fontSize: "13px",
                          }}
                        />
                      </span> */}
                        </div>
                      </div>
                      {/* </div> */}
                    </th>

                    {/* <th>
                      Planned EBITDA
                      <span
                        onClick={() => handleFilterChange("PlannedEBITDA")}
                        className={
                          appliedFilters.PlannedEBITDA ? "active-filter" : ""
                        }
                        ref={filterRefs[8]}
                        style={{ cursor: "pointer" }}
                      >
                       <FaFilter
                          style={{ marginLeft: "5px", cursor: "pointer", fontSize:"10px" }}
                        />
                      </span>
                      <span
                        onClick={() =>
                          handleSort(
                            "plannedEbitda",
                            sortDirectionPlannedEBITDA,
                            setSortDirectionPlannedEBITDA
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {sortDirectionPlannedEBITDA === "ascending" && (
                          <FaAngleUp />
                        )}
                        {sortDirectionPlannedEBITDA === "descending" && (
                          <FaAngleDown />
                        )}
                        {sortDirectionPlannedEBITDA === "none" && (
                          <FaSort style={{ marginLeft: "5px" }} />
                        )}
                      </span>
                      <div ref={filterRefs[9]}>
                        {filterVisibility.PlannedEBITDA && (
                          <div className="filter-item">
                            {Array.from(
                              new Set(
                                projects.map((project) => project.plannedEbitda)
                              )
                            ).map((value, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  handleFilterValueChange(
                                    "PlannedEBITDA",
                                    value
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  padding: "5px 10px",
                                  backgroundColor: plannedEbitdaFilter.includes(
                                    value
                                  )
                                    ? "lightblue"
                                    : "transparent",
                                }}
                              >
                                {value}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </th> */}
                    {/* <th>
                      Target EBITDA
                      <span
                        onClick={() => handleFilterChange("TargetEBITDA")}
                        className={
                          appliedFilters.TargetEBITDA ? "active-filter" : ""
                        }
                        style={{ cursor: "pointer" }}
                        ref={filterRefs[10]}
                      >
                       <FaFilter
                          style={{ marginLeft: "5px", cursor: "pointer", fontSize:"10px" }}
                        />
                      </span>
                      <span
                        onClick={() =>
                          handleSort(
                            "targetEbitda",
                            sortDirectionTargetEBITDA,
                            setSortDirectionTargetEBITDA
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {sortDirectionTargetEBITDA === "ascending" && (
                          <FaAngleUp />
                        )}
                        {sortDirectionTargetEBITDA === "descending" && (
                          <FaAngleDown />
                        )}
                        {sortDirectionTargetEBITDA === "none" && (
                          <FaSort style={{ marginLeft: "5px" }} />
                        )}
                      </span>
                      <div ref={filterRefs[11]}>
                        {filterVisibility.TargetEBITDA && (
                          <div className="filter-item">
                            {Array.from(
                              new Set(
                                projects.map((project) => project.targetEbitda)
                              )
                            ).map((value, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  handleFilterValueChange("TargetEBITDA", value)
                                }
                                style={{
                                  cursor: "pointer",
                                  padding: "5px 10px",
                                  backgroundColor: targetEbitdaFilter.includes(
                                    value
                                  )
                                    ? "lightblue"
                                    : "transparent",
                                }}
                              >
                                {value}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </th> */}
                    {/* <th>
                      Actual EBITDA
                      <span
                        onClick={() => handleFilterChange("ActualEBITDA")}
                        className={`filter-icon ${
                          appliedFilters.ActualEBITDA ? "active-filter" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        ref={filterRefs[12]}
                      >
                       <FaFilter
                          style={{ marginLeft: "5px", cursor: "pointer", fontSize:"10px" }}
                        />
                      </span>
                      <span
                        onClick={() =>
                          handleSort(
                            "actualEbitda",
                            sortDirectionActualEBITDA,
                            setSortDirectionActualEBITDA
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {sortDirectionActualEBITDA === "ascending" && (
                          <FaAngleUp />
                        )}
                        {sortDirectionActualEBITDA === "descending" && (
                          <FaAngleDown />
                        )}
                        {sortDirectionActualEBITDA === "none" && (
                          <FaSort style={{ marginLeft: "5px" }} />
                        )}
                      </span>
                      <div ref={filterRefs[13]}>
                        {filterVisibility.ActualEBITDA && (
                          <div className="filter-item">
                            {Array.from(
                              new Set(
                                projects.map((project) => project.actualEbitda)
                              )
                            ).map((value, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  handleFilterValueChange("ActualEBITDA", value)
                                }
                                style={{
                                  cursor: "pointer",
                                  padding: "5px 10px",
                                  backgroundColor: actualEbitdaFilter.includes(
                                    value
                                  )
                                    ? "lightblue"
                                    : "transparent",
                                }}
                              >
                                {value}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </th> */}
                    {/* <th>Planned EBITDA %</th> */}
                    <th className="maxi">Project Margin Planned (%)</th>
                    <th className="maxi">Project Margin Target (%)</th>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: tooltipStyles.tooltip,
                        },
                      }}
                      title={<ToolTipComponent />}
                    >
                      <th className="maxi">Project Margin Actual (%)</th>
                    </Tooltip>
                    {/* </Tooltip><th className="maxi" title={tooltipContent}>
    Actual EBITDA (%)
    
  </th> */}
                    <th className="maxi">Effort Delay (hrs)</th>
                    <th className="maxi">Extension (hrs)</th>
                    <th className="maxi">CR (hrs)</th>
                    {/* <th>Efforts Delay By Over Logging %</th> */}

                    {/**By Extension Tickets
                     */}
                    {/* <th>Efforts Delay By Extension Tickets%</th> */}
                    {/* <th>Expected Min Billing</th>
                    <th>Expected Max Billing</th> */}
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: tooltipStyles.tooltip,
                        },
                      }}
                      title={<BillingStatus />}
                    >
                      <th>Underbilling/Overbilling Status (₹) </th>
                    </Tooltip>
                    <th>Revenue Absorbed (₹) </th>

                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: tooltipStyles.tooltip,
                        },
                      }}
                      title={<Satisfaction />}
                    >
                      <th>Team Satisfaction </th>
                    </Tooltip>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: tooltipStyles.tooltip,
                        },
                      }}
                      title={<Satisfaction />}
                    >
                      <th>Customer Satisfaction </th>
                    </Tooltip>
                    <th className="ellipsis" title="Technical Lead">
                      TL
                    </th>
                    <th className="ellipsis" title="Project Owner">
                      PO
                    </th>
                    <th className="ellipsis" title="Delivery Manager">
                      DM
                    </th>
                    <th className="ellipsis" title="Project Manager">
                      PM
                    </th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                {filterProjects.length > 0 ? (
                  <TableBodys
                    filterProjects={filterProjects}
                    expandedProjectId={expandedProjectId}
                    handleProjectNameClick={handleProjectNameClick}
                    handleEditClick={handleEditClick}
                    setFilterProjects={setFilterProjects}
                    setProjects={setProjects}
                  />
                ) : (
                  <tbody>
                    <tr>
                      <td
                        colSpan={30}
                        style={{ position: "relative", height: "50px" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            padding: "10px",
                            fontSize: "1rem",
                            color: "#333",
                          }}
                        >
                          No Projects Found
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
          <div className="filter-controls">
            {watchActiveFilters ? (
              <button onClick={clearAllFilters}>Clear All Filters</button>
            ) : (
              <button className="disabled" onClick={clearAllFilters} disabled>
                Clear All Filters
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InternalDashboardTable;
