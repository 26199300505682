import React, { createContext, useReducer, useContext, ReactNode, useState } from "react";

interface GlobalState {
  tokenExpired: boolean;
}

interface GlobalStateProviderProps {
  children: ReactNode;
}

const initialState: GlobalState = {
  tokenExpired: false,
};

// Create contexts for global state and dispatch
const GlobalStateContext = createContext<GlobalState>(initialState);
const GlobalDispatchContext = createContext<React.Dispatch<any>>(() => null);
const ProjectsContext = createContext<{
  contextData: any[];
  setContextData: (newProjects: any) => void;
} | null>(null);
const PeopleDashboardContext = createContext<{
  contextData: any[];
  setContextData: (newProjects: any) => void;
} | null>(null);

// Define the reducer function to manage state updates
const reducer = (state: GlobalState, action: { type: string; payload: any }): GlobalState => {
  switch (action.type) {
    case "SET_TOKEN_EXPIRED":
      return {
        ...state,
        tokenExpired: action.payload,
      };
    default:
      return state;
  }
};

// Create the GlobalStateProvider component to provide state and dispatch to its children
export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};


export const ProjectsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [contextData, setContextData] = useState<any[]>([]); // Define state to hold project data

  const contextValue = {
    contextData,
    setContextData 
  };

  return (
    <ProjectsContext.Provider value={contextValue}>
      {children}
    </ProjectsContext.Provider>
  );
};

export const PeopleDashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [contextData, setContextData] = useState<any[]>([]); // Define state to hold project data

  const contextValue = {
    contextData,
    setContextData 
  };

  return (
    <PeopleDashboardContext.Provider value={contextValue}>
      {children}
    </PeopleDashboardContext.Provider>
  );
}

// Custom hooks to use the global state and dispatch in other components
export const useGlobalState = () => useContext(GlobalStateContext);
export const useGlobalDispatch = () => useContext(GlobalDispatchContext);
export const useProjects = () => useContext(ProjectsContext);
export const usePeopleDashboard = () => useContext(PeopleDashboardContext);