import React, { useEffect, useRef, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import { IoAdd, IoArrowBack, IoSync } from "react-icons/io5";
import DashboardHeader from "components/DashboardHeader";
import IconButton from "components/IconButton";
import SelectComponent from "components/SelectComponent";
import { VscFilter } from "react-icons/vsc";
import DateRangePicker from "components/DateCalendar";
import Alert from "@mui/material/Alert";
import IconButtonMUI from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./index.scss";
import {
  downloadTimesheet,
  postTimesheetData,
  syncDB,
  getAllProjects,
  ManualSyncProject,
  checkProjectInDB,
  getResourceByEmailId,
  getSyncStatus,
} from "common/api/ApiService";
import QuickFilterComponent from "components/QuickFilter";
import TimeSheetTypeComponent from "components/TimeSheetsSelect";
import TimesheetDefaulters from "components/TimesheetDefaulters";
import { Cookie } from "@mui/icons-material";
import ProjectWiseTable from "components/ProjectWiseReport";
import PersonTable from "components/PersonWiseTable/Index";
import ProjectTable from "components/ProjectTable";
import { useLocation, useNavigate } from "react-router-dom";
import { VscEye } from "react-icons/vsc";
import { IoMenu } from "react-icons/io5";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import BenchTimeTable from "components/BenchTime";
import { TbFileReport } from "react-icons/tb";
import { error } from "console";

interface Project {
  project_id: string;
  name: string;
  jira_project_id_ext: number;
  is_internal: number;
}

interface ManualSyncModalProps {
  open: boolean;
  onClose: () => void;
}

interface ProjectData {
  name: string;
  issues: {
    name: string;
    description: string | null;
    issueId: number;
    assignee: {
      firstName: string | null;
    };
    timeLogs: {
      loggedTime: number;
      comments: string | null;
      jiraTimeLoggedAt: string;
    }[];
  }[];
}

interface ProjectSummary {
  project: string;
  assignees: AssigneeData[];
}

interface AssigneeData {
  name: string;
  issues: {
    monthYear: string;
    workItems: WorkItem[];
  }[];
}
interface WorkItem {
  name: string;
  time: number;
  date: string;
}
interface Payload {
  projectId: number[];
  fromDate: string;
  toDate: string;
}

interface SyncPayload {
  project_ids_ext: number[];
  project_ids: number[];
}

interface DashboardProps {
  selectedProjectData: ProjectData[];
  setSelectedProjectData: React.Dispatch<React.SetStateAction<ProjectData[]>>;
  hasAccess: boolean;
  syncRequestId: number;
  setSyncRequestId: React.Dispatch<React.SetStateAction<number>>;
}

const Dashboard: React.FC<DashboardProps> = ({
  selectedProjectData,
  setSelectedProjectData,
  hasAccess,
  syncRequestId,
  setSyncRequestId,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Project[]>([]);
  const [selectedDates, setSelectedDates] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const [payload, setPayload] = useState<Payload>({
    projectId: [],
    fromDate: "",
    toDate: "",
  });
  const [syncPayload, setSyncPayload] = useState<SyncPayload>({
    project_ids_ext: [],
    project_ids: [],
  });
  const [showTable, setShowTable] = useState(false);
  const [alert, setAlert] = useState<{
    show: boolean;
    message: string;
    type: string;
  }>({ show: false, message: "", type: "" });
  const [isLogged, setIsLogged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  // const [projectData, setProjectData] = useState<ProjectData[]>([]);
  const [selectedRange, setSelectedRange] = useState("previousWeek");
  const [selectedTimesheet, setSelectedTimesheet] = useState<string>("");
  const [selectedShowTimesheet, setSelectedShowTimesheet] =
    useState<string>("");
  const [clearSearch, setClearSearch] = useState(false);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [isManualSyncModalOpen, setManualSyncModalOpen] = useState(false);
  const [manualSyncInput, setManualSyncInput] = useState("");
  const [synchasAccess, setSynchasAccess] = useState<boolean>(true);
  const [syncaccess, setSyncAccess] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setAnchorEl(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManualSyncModalPopUp = () => {
    setManualSyncModalOpen(true);
  };

  //fetch data for each project based on the payload
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       if (selectedTimesheet !== "TimeSheetDefaulters") {
  //         const projects: ProjectData[] = await postTimesheetData(payload);
  //         // console.log(projects);
  //         setProjectData(projects);
  //       }
  //     } catch (error) {
  //       setAlert({
  //         show: true,
  //         message: "Error while fetching projects data",
  //         type: "error",
  //       });
  //       setTimeout(() => {
  //         setAlert({ show: false, message: "", type: "" });
  //       }, 5000);
  //       console.error("Error fetching projects data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [payload]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLogged) {
          const projectsData = await getAllProjects(1, [1, 2], 0);
          // const list = projectsData.map((item, index) => index + 1);
          setProjectList(projectsData);
        }
      } catch (error) {
        setAlert({
          show: true,
          message: "Error while fetching projects",
          type: "error",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 5000);
        console.error("Error fetching projects:", error);
      }
    };
    fetchData();
  }, []);

  const fetchSyncStatus = async () => {
    try {
      const statusIdData = await getSyncStatus(syncRequestId);
      const statusId = parseInt(statusIdData.status);
      if (statusId === 3) {
        setAlert({
          show: true,
          message: "Sync successful!",
          type: "success",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 3000);
        setSyncAccess(true);
        setSyncLoading(false);
        setSynchasAccess(true);
        setSyncRequestId(0);
      } else if (statusId === 4) {
        setAlert({
          show: true,
          message: "Sync failed. Please try again.",
          type: "error",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 3000);
        setSyncAccess(true);
        setSyncLoading(false);
        setSynchasAccess(true);
        setSyncRequestId(0);
      }
    } catch (error) {
      console.error("Error fetching sync details:", error);
      setAlert({
        show: true,
        message: "Error while fetching sync details",
        type: "error",
      });
      setSyncAccess(true);
      setSyncLoading(false);
      setSynchasAccess(true);
      setSyncRequestId(0);
    }
  };

  useEffect(() => {
    // console.log("requestId:", requestId);

    let intervalId: NodeJS.Timeout | undefined;

    if (syncRequestId) {
      setSyncAccess(false);
      setSyncLoading(true);
      fetchSyncStatus(); // Initial call
      intervalId = setInterval(fetchSyncStatus, 30000); // Call every minute
    }
    return () => {
      if (intervalId) clearInterval(intervalId); // Cleanup
    };
  }, [syncRequestId]);

  useEffect(() => {
    const email = sessionStorage.getItem("email");
    if (email) {
      getResourceByEmailId(email).then((resp) => {
        if (resp) {
          if (resp.sync.syncAccess === 1) {
            setSynchasAccess(true);
            setSyncRequestId(0);
            setSyncLoading(false);
          } else if (resp.sync.syncAccess === 0) {
            setSynchasAccess(false);
            setSyncRequestId(resp.sync.requestId);
            setSyncLoading(true);
          }
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     selectedTimesheet === "TimeSheetDefaulters" &&
  //     selectedDates.endDate &&
  //     selectedDates.startDate
  //   ) {
  //     const projectIds = projectList.map((option) =>
  //       parseInt(option.project_id)
  //     );
  //     // console.log(projectIds)

  //     // Create the payload object
  //     const payload = {
  //       projectId: projectIds,
  //       fromDate: selectedDates.startDate,
  //       toDate: selectedDates.endDate,
  //     };
  //     // console.log(payload);
  //     // Set the payload state
  //     setPayload(payload);
  //     setSelectedOptions(projectList);
  //   }
  // }, [selectedTimesheet, selectedDates]);

  //clear all states
  const handleClearAll = () => {
    setSelectedOptions([]);
    setSelectedDates({ startDate: null, endDate: null });
    setShowTable(false);
    setAlert({ show: false, message: "", type: "" });
    setSelectedRange("");
    setClearSearch(true);
    setSelectedTimesheet("");
  };

  //To show the table data w.r.t payload
  const handleGo = () => {
    setSelectedShowTimesheet(selectedTimesheet);
    if (selectedTimesheet !== "TimeSheetDefaulters") {
      if (!selectedDates.startDate) {
        setAlert({
          show: true,
          message: "Please select a start date.",
          type: "error",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 5000);
        return;
      }

      if (!selectedDates.endDate) {
        setAlert({
          show: true,
          message: "Please select an end date.",
          type: "error",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 5000);
        return;
      }

      if (selectedOptions.length === 0) {
        setAlert({
          show: true,
          message: "Please select project.",
          type: "error",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 5000);
        return;
      }

      if (!selectedTimesheet) {
        setAlert({
          show: true,
          message: "Please select a timesheet.",
          type: "error",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 5000);
        return;
      }

      // Extract project IDs from selected options
      const projectIds = selectedOptions.map((option) =>
        parseInt(option.project_id)
      );

      // setSelectedOptions(projectList);
      // Create the payload object
      const payload = {
        projectId: projectIds,
        fromDate: selectedDates.startDate,
        toDate: selectedDates.endDate,
      };
      // console.log(payload);
      // Set the payload state
      setPayload(payload);
      setShowTable(true);
      setAlert({ show: false, message: "", type: "" });
    } else {
      if (!selectedDates.startDate) {
        setAlert({
          show: true,
          message: "Please select a start date.",
          type: "error",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 5000);
        return;
      }

      if (!selectedDates.endDate) {
        setAlert({
          show: true,
          message: "Please select an end date.",
          type: "error",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 5000);
        return;
      }

      // const projectIds = projectList.map((proj) => parseInt(proj.project_id));
      const projectIds = selectedOptions.map((option) =>
        parseInt(option.project_id)
      );
      // console.log(projectList)
      // console.log(projectIds)
      const payload = {
        projectId: projectIds,
        fromDate: selectedDates.startDate,
        toDate: selectedDates.endDate,
      };
      // console.log(payload);

      // Set the payload state
      setPayload(payload);
      setShowTable(true);
      setAlert({ show: false, message: "", type: "" });
    }
  };

  const handleCloseModal = () => {
    setManualSyncModalOpen(false);
    setManualSyncInput("");
  };

  const ManualSyncModal: React.FC<ManualSyncModalProps> = ({
    open,
    onClose,
  }) => {
    const [manualSyncInput, setManualSyncInput] = useState("");
    const [error, setError] = useState<string>("");
    const [isChecked, setIsChecked] = useState(false);
    const projectCheckMessage =
      "Project does not exist in timesheets u can sync here";
    const handleSyncModal = async () => {
      const input = manualSyncInput.toUpperCase();
      await handleManualSync(input, setError);
    };
    const errorStyle =
      error === projectCheckMessage
        ? { color: "green" } // Green color for the projectCheckMessage
        : { color: "red" };

    const handleCheckModal = async () => {
      const input = manualSyncInput.toUpperCase();
      const resp = await handleManualSyncCheck(input, setError);
      if (resp.message === projectCheckMessage) setIsChecked(true);
    };

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="manual-sync-modal-title"
        aria-describedby="manual-sync-modal-description"
      >
        <div className="modal-content-dashboard">
          <h2 id="manual-sync-modal-title">Manual Sync</h2>
          <input
            type="text"
            value={manualSyncInput}
            onChange={(e) => {
              setManualSyncInput(e.target.value);
              setError("");
              setIsChecked(false);
            }}
            placeholder="Enter Project Key or Project Jira ID"
            style={{ marginBottom: 10 }}
          />
          {error && (
            <p className="error-message-manual-sync" style={errorStyle}>
              {error}
            </p>
          )}
          <div className="modal-buttons">
            <Button onClick={handleCheckModal} disabled={!manualSyncInput}>
              Check
            </Button>
            <Button onClick={handleSyncModal} disabled={!isChecked}>
              Sync
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </div>
        </div>
      </Modal>
    );
  };

  //handle sync based on selected projects
  const handleSync = async () => {
    setSyncLoading(true);
    setSyncAccess(false);
    setSynchasAccess(false);
    const projectIds = selectedOptions.map((option) =>
      parseInt(option.project_id)
    );
    const projectExtIds = selectedOptions.map(
      (option) => option.jira_project_id_ext
    );

    const syncPayloadData = {
      project_ids_ext: projectExtIds,
      project_ids: projectIds,
      email: sessionStorage.getItem("email"),
    };
    setSyncPayload(syncPayloadData);
    try {
      const syncSuccessRequestIdData = await syncDB(syncPayloadData);
      if (syncSuccessRequestIdData) {
        setSyncRequestId(syncSuccessRequestIdData.syncId);
      }
    } catch (error) {
      console.error("Error during sync:", error);
      setAlert({
        show: true,
        message: "Sync failed. Please try again.",
        type: "error",
      });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 5000);
      setSyncAccess(true);
    }
  };

  const handleManualSyncCheck = async (
    input: string | number,
    setError: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      const manualSync = await checkProjectInDB(input);
      if (manualSync.statusCode === 200 || manualSync.statusCode === 201) {
        setError(manualSync.message);
      } else {
        setError(manualSync.message);
      }
      return manualSync;
    } catch (error) {
      setError("Failed to check");
      return error;
    }
  };

  const handleManualSync = async (
    input: string | number,
    setError: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      // setLoading(true);
      setSyncLoading(true);
      setSynchasAccess(false);
      setSyncAccess(false);
      handleCloseModal();
      const manualSync = await ManualSyncProject(input);
      // console.log(manualSync);
      if (manualSync.statusCode === 200 || manualSync.statusCode === 201) {
        setAlert({
          show: true,
          message: manualSync.message,
          type: "success",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 5000);
        setSyncAccess(true);
        setSynchasAccess(true);
        setSyncLoading(false);
      } else {
        setAlert({
          show: true,
          message: manualSync.message,
          type: "error",
        });
        setTimeout(() => {
          setAlert({ show: false, message: "", type: "" });
        }, 5000);
        setSyncAccess(true);
        setSynchasAccess(true);
        setSyncLoading(false);
      }
    } catch {
      console.error("error");
      setAlert({ show: true, message: "Failed to Sync", type: "error" });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 5000);
      setSyncAccess(true);
      setSynchasAccess(true);
      setSyncLoading(false);
    } finally {
      setLoading(false);
      setSyncAccess(true);
      setSynchasAccess(true);
      setSyncLoading(false);
    }
  };

  //Handle download based on payload sent(projectid,fromdate,todate)
  const handleDownload = async () => {
    const projectIds = selectedOptions.map((option) =>
      parseInt(option.project_id)
    );

    // Create the payload object
    const payload = {
      projectId: projectIds,
      fromDate: selectedDates.startDate || "",
      toDate: selectedDates.endDate || "",
    };

    // Set the payload state
    // setPayload(payload);

    try {
      const fileBlob = await downloadTimesheet(payload, selectedTimesheet);
      const url = window.URL.createObjectURL(new Blob([fileBlob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${selectedTimesheet}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setAlert({
        show: true,
        message: "Timesheet Downloaded Successfully",
        type: "success",
      });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 5000);
      return;
    } catch (error) {
      console.error("Error handling download:", error);
      setAlert({ show: true, message: "Failed to Download", type: "error" });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 5000);
      return;
    }
  };

  useEffect(() => {
    const { state } = location;
    if (state) {
      setSelectedOptions(state.selectedOptions || []);
      setSelectedTimesheet(state.selectedTimesheet || "");
      setClearSearch(state.clearSearch || false);
    }
  }, [location]);

  const handleCloseAlert = () => {
    setAlert({ show: false, message: "", type: "" });
  };

  // Check if the user is logged in
  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (token) setIsLogged(true);
    else setIsLogged(false);
  }, []);

  // Display alert if session expired
  useEffect(() => {
    const expire = sessionStorage.getItem("expired");
    if (expire)
      setAlert({ show: true, message: "Session Expired", type: "warning" });
  }, [Cookie]);

  // Disable actions based on conditions
  const isActionDisabled = !selectedDates.startDate ||
    !selectedDates.endDate ||
    selectedOptions?.length === 0 ||
    !isLogged;
  const isSyncDisabled: boolean = selectedOptions?.length === 0 || !isLogged;
  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {syncLoading && (
        <div className="sync-loading-overlay" title="Sync in progress...">
          <IoSync className="sync-icon" />
        </div>
      )}
      <DashboardHeader />
      {alert.show && (
        <Alert
          severity={alert.type === "success" ? "success" : "error"}
          className="floating-alert"
          action={
            <IconButtonMUI
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseAlert}
            >
              <CloseIcon fontSize="inherit" />
            </IconButtonMUI>
          }
        >
          {alert.message}
        </Alert>
      )}
      {isLogged && (
        <div className="dashboard__container">
          {hasAccess && (
            <span onClick={() => navigate("/")} className="back-icon">
              <IoArrowBack size={24} />
            </span>
          )}
          <TimeSheetTypeComponent
            selectedTimeSheetType={selectedTimesheet}
            setSelectedTimeSheetType={setSelectedTimesheet}
          />

          <SelectComponent
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            isDisabled={!isLogged}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />

          <DateRangePicker
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
          />

          <QuickFilterComponent
            setSelectedDates={setSelectedDates}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />
          <button
            className="show-button"
            onClick={() => {
              handleGo();
            }}
          >
            <span className="show-button-name">Show</span>
            <VscEye />
          </button>
          <div>
            <div className="menu-icon-div">
              <span onClick={handleClick} className="menu-icon">
                <IoMenu />
              </span>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClearAll();
                  handleClose();
                }}
              >
                <span className="menu-item">
                  <span className="menu-item-name"> Clear All</span>
                  <RxCross1 />
                </span>
              </MenuItem>
              {hasAccess && (
                <MenuItem
                  onClick={() => {
                    handleSync();
                    handleClose();
                  }}
                  disabled={isSyncDisabled || !hasAccess || !syncaccess}
                  // disabled={isSyncDisabled || !hasAccess || !synchasAccess}
                >
                  <span className="menu-item">
                    <span className="menu-item-name">Sync</span>
                    <IoSync />
                  </span>
                </MenuItem>
              )}
              {hasAccess && (
                <MenuItem
                  onClick={() => {
                    handleManualSyncModalPopUp();
                    handleClose();
                  }}
                  disabled={!hasAccess || !synchasAccess}
                  // disabled={!hasAccess ||}
                >
                  <span className="menu-item">
                    <span className="menu-item-name">Manual Sync</span>
                    <IoAdd />
                  </span>
                </MenuItem>
              )}
              {hasAccess && (
                <MenuItem
                  onClick={() => {
                    navigate("/project-iq/sync-report");
                  }}
                >
                  <span className="menu-item">
                    <span className="menu-item-name">Sync Report</span>
                    <TbFileReport />
                  </span>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  handleDownload();
                  handleClose();
                }}
                disabled={isActionDisabled}
              >
                <span className="menu-item">
                  <span className="menu-item-name">Download</span>
                  <FiDownload />
                </span>
              </MenuItem>
            </Menu>
          </div>
        </div>
      )}
      {isLogged && showTable && (
        <>
          {selectedShowTimesheet === "Timesheet" && (
            <ProjectTable
              payload={payload}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {selectedShowTimesheet === "TimeSheetDefaulters" && (
            <TimesheetDefaulters
              payload={payload}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {selectedShowTimesheet === "ProjectWiseTimesheet" && (
            <ProjectWiseTable
              payload={payload}
              loading={loading}
              setLoading={setLoading}
              selectedProjectData={selectedProjectData}
              setSelectedProjectData={setSelectedProjectData}
            />
          )}
          {selectedShowTimesheet === "PersonWiseTimesheet" && (
            <PersonTable
              payload={payload}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {selectedShowTimesheet === "BenchTime" && (
            <BenchTimeTable
              payload={payload}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </>
      )}
      <ManualSyncModal
        open={isManualSyncModalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default Dashboard;
