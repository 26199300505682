import { Box, Button, Modal as MUIModal } from "@mui/material";
import "./index.scss";
import { formatValue, getColor } from "utils/AppUtils";

interface ProcessAdherenceDataModalProps {
  openAdherence: boolean;
  handleCloseAdherence: () => void;
  projectAdherenceData: any;
}

const ProcessAdherenceDataModal = ({
  openAdherence,
  handleCloseAdherence,
  projectAdherenceData,
}: ProcessAdherenceDataModalProps) => {

  return (
    <MUIModal
      open={openAdherence}
      onClose={handleCloseAdherence}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="modal-box-style">
        {projectAdherenceData && (
          <>
            <h3>Process Adherence Data</h3>
            {Object.values(projectAdherenceData).map((data: any) => (
              <p key={data?.name}>
                {data?.name.toUpperCase()}:{" "}
                <span style={{ color: getColor(data?.value) }}>
                  {formatValue(data?.value)}
                </span>
              </p>
            ))}
          </>
        )}
        <div className="modal-button">
          <Button onClick={handleCloseAdherence}>Cancel</Button>
        </div>
      </Box>
    </MUIModal>
  );
};
export default ProcessAdherenceDataModal;
