// // TableBody.tsx

import React, { useState } from "react";
import { FaAngleDown, FaEdit, FaTrash } from "react-icons/fa";
import "./index.scss";
import {
  deleteProjectById,
  ProjectAdherenceDataProps,
  ProjectRespData,
  ProjectRespDataInternal,
} from "common/api/ApiService";
import { departmentOptions } from ".";
import { capitalize } from "utils/Capitalize";
import { trimAndTooltip } from "utils/TrimandToolTip";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import ProcessAdherenceDataModal from "components/ProcessAdherenceDataModal";

interface Props {
  filterProjects: ProjectRespDataInternal[];
  setFilterProjects: React.Dispatch<React.SetStateAction<ProjectRespDataInternal[]>>;
  expandedProjectId: number | null;
  handleProjectNameClick: (projectId: number) => void;
  handleEditClick: (projectId: string) => void;
  setProjects: React.Dispatch<React.SetStateAction<ProjectRespDataInternal[]>>;
}

const initialProcessAdherenceData = {
  tl: { name: "", value: 0 },
  pm: { name: "", value: 0 },
  po: { name: "", value: 0 },
  individual: { name: "", value: 0 },
  design: { name: "", value: 0 },
}

const TableBodys: React.FC<Props> = ({
  filterProjects,
  expandedProjectId,
  handleProjectNameClick,
  handleEditClick,
  setFilterProjects,
  setProjects,
  
}) => {
  const [open, setOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<number | null>(null);
  const [projectAdherenceData, setProjectAdherenceData] = useState<
    ProjectAdherenceDataProps | undefined
  >(initialProcessAdherenceData);
  const [openAdherence, setOpenAdherence] = useState(false);

  const handleOpen = (projectId: number) => {
    setProjectToDelete(projectId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setProjectToDelete(null);
  };
  const handleConfirmDelete = async() => {
    if (projectToDelete !== null) {
      const res = await deleteProjectById(projectToDelete);
      if (res.statusCode === 200) {
        setFilterProjects((prevProjects) => prevProjects.filter(
          (project) => project.projectId !== projectToDelete
        ));
        setProjects((prevProjects) => prevProjects.filter(
          (project) => project.projectId !== projectToDelete
        ));
        }   
      handleClose();
    }
  };

  const getStatusColor = (status: string) => {
    // console.log(status);
    switch (status) {
      case "Not Started":
        return "gray";
      case "In Progress":
        return "yellow";
      case "Closed":
        return "green";
      case "On Hold":
        return "orange";
      default:
        return "transparent";
    }
  };

  const renderField = (field: any) => {
    if (!field) return "";
    if (typeof field === "string") return field;
    return field?.name || "--";
  };

  const getEbidtaColorStatus = (
    actualEbitdaPercent: number,
    targetEbitdaPercent: number
  ): string => {
    if (targetEbitdaPercent - actualEbitdaPercent < 0) {
      return "lightgreen";
    } else if (
      targetEbitdaPercent - actualEbitdaPercent >= 0 &&
      targetEbitdaPercent - actualEbitdaPercent <= 5
    ) {
      return "green";
    } else if (
      targetEbitdaPercent - actualEbitdaPercent > 5 &&
      targetEbitdaPercent - actualEbitdaPercent <= 10
    ) {
      return "yellow";
    } else if (
      targetEbitdaPercent - actualEbitdaPercent > 10 &&
      targetEbitdaPercent - actualEbitdaPercent <= 15
    ) {
      return "orange";
    } else {
      return "red";
    }
  };

  const getBillingStatusColor = (
    billingStatusRate: number,
    expectedCurrentMinBilling: number,
    expectedCurrentMaxBilling: number
  ): string => {
    if (
      billingStatusRate >= expectedCurrentMinBilling &&
      billingStatusRate <= expectedCurrentMaxBilling
    ) {
      return "green"; // On budget
    } else if (billingStatusRate < expectedCurrentMinBilling) {
      return "red"; // Under budget
    } else {
      return "yellow"; // Over budget
    }
  };

  const getBackgroundColorSatisfaction = (satisfaction: string) => {
    switch (satisfaction) {
      case "A+":
        return "green";
      case "A":
        return "lightgreen";
      case "B":
        return "yellow";
      case "C":
        return "orange";
      case "D":
        return "red";
      default:
        return "transparent";
    }
  };

  //   const formatRupees = (amount: number): string => {
  //   return new Intl.NumberFormat("en-IN").format(amount);
  // };
  const formatRupees = (amount: number): string => {
    return new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const getRevenueAbsorbedStatusColor = (Rev_ab: number): string => {
    // console.log(percentage)
    if (Rev_ab === 0) {
      return "green";
    } else if (Rev_ab > 0) {
      return "red";
    } else {
      return "transparent";
    }
  };

  const getEffortDelayStatus = (value: number): string => {
    if (value <= 5) return "green";
    else if (value > 5 && value <= 10) return "yellow";
    else if (value > 10 && value <= 15) return "orange";
    else if (value > 15) return "red";
    else {
      return "red";
    }
  };

  const renderUserDetails = (user: any) => {
    if (!user) return "";
    let userDetails;
    try {
      userDetails = user;
    } catch (error) {
      console.error("Error parsing user details:", error);
      return "";
    }
    if (userDetails.firstName === null) return "N/A";
    const name = capitalize(
      userDetails.firstName + "" + (userDetails.lastName || "")
    );
    return `${name} `;
  };

  const handleProjectAdherenceValues = (projectId: number) => {
    const project = filterProjects?.find(
      (project) => project.projectId === projectId
    );
    if (
      project &&
      project.processAdherencePercentageData &&
      project.activeSprint
    ) {
      setProjectAdherenceData(project.processAdherencePercentageData);
      setOpenAdherence(true);
    } else {
      console.log("Invalid project data", project);
    }
  };

  const handleCloseAdherence = () => {
    setOpenAdherence(false);
    setProjectAdherenceData(initialProcessAdherenceData);
  };
  const getBackgroundColor = (project: ProjectRespDataInternal) => {
    if (!project.activeSprint) {
      return "red";
    } else if (Number(project.processAdherence) < 50) {
      return "#fc9003";
    }
    return "transparent";
  };

  return (
    <>
      <tbody>
        {filterProjects?.map((project) => (
          <React.Fragment key={project.projectId}>
            <tr>
              {project.subProjects ? (
                <td
                  className="fixed"
                  onClick={() => handleProjectNameClick(project.projectId)}
                  style={{ cursor: "pointer" }}
                >
                  {capitalize(project.name)}{" "}
                  <FaAngleDown style={{ marginTop: "5px" }}></FaAngleDown>
                </td>
              ) : (
                <td
                  className="fixed"
                  onClick={() => handleProjectNameClick(project.projectId)}
                  style={{ cursor: "pointer" }}
                >
                  {trimAndTooltip(capitalize(project.name), 7)}
                </td>
              )}

              <td>{renderField(project.projectType)}</td>
              <td>{project.criticality}</td>
              <td>
                {departmentOptions.find(
                  (option) => option.value === project.departmentId
                )?.label || "Unknown"}
              </td>
              <td
                style={{
                  backgroundColor: getStatusColor(
                    renderField(project.projectStatus)
                  ),
                }}
              >
                {renderField(project.projectStatus)}
              </td>
              <td className="align-right">
                {formatRupees(
                  Number(project.totalQuote) + Number(project.totalQuoteQA)
                )}
              </td>
              <td className="align-right">
                {formatRupees(Number(project.currentRevenue))}
              </td>
              <td
                className="align-right"
                style={{
                  backgroundColor: getBackgroundColor(project),
                  cursor: project.activeSprint ? "pointer" : "not-allowed",
                }}
                onClick={() => handleProjectAdherenceValues(project.projectId)}
              >
                {project.activeSprint
                  ? Number(project.processAdherence).toFixed(2)
                  : "No Active Sprint"}
              </td>
              {/* <td className="align-right">
              {formatRupees(project.plannedEbitda)}
            </td>
            <td className="align-right">
              {formatRupees(project.targetEbitda)}
            </td>
            <td className="align-right">
              {formatRupees(project.actualEbitda)}
            </td> */}
              <td className="align-right">
                {project.plannedEbitdaPercent?.toFixed(1)}
              </td>
              <td className="align-right">
                {project.targetEbitdaPercent?.toFixed(1)}
              </td>
              <td
                className="align-right"
                style={{
                  backgroundColor: getEbidtaColorStatus(
                    project.actualEbitdaPercent,
                    project.targetEbitdaPercent
                  ),
                }}
              >
                {project.actualEbitdaPercent?.toFixed(1) || "--"}
              </td>
              <td className="align-right">{project.effortDelay?.toFixed(1)}</td>
              <td className="align-right">
                {project.extensionLoggedEfforts?.toFixed(1)}
              </td>
              <td className="align-right">
                {project.crConsumedEfforts?.toFixed(1)}
              </td>
              {/* <td
              className="align-right"
              style={{
                backgroundColor: getEffortDelayStatus(
                  project.effortsDelayByOverLoggingPercent
                ),
              }}
            >
              {project.effortsDelayByOverLoggingPercent?.toFixed(1)}
            </td> */}

              {/* <td
              className="align-right"
              style={{
                backgroundColor: getEffortDelayStatus(
                  project.effortsDelayByExtTicketsPercent
                ),
              }}
            >
              {project.effortsDelayByExtTicketsPercent?.toFixed(1)}
            </td> */}
              {/* <td className="align-right">
              {formatRupees(project.expectedMinBilling)}
            </td>
            <td className="align-right">
              {formatRupees(project.expectedMaxBilling)}
            </td> */}
              <td
                className="align-right"
                style={
                  project.projectType.id === 1 || project.projectType.id === 4
                    ? {
                        backgroundColor: getBillingStatusColor(
                          project.billingStatusRate,
                          project.expectedCurrentMinBilling,
                          project.expectedCurrentMaxBilling
                        ),
                      }
                    : {}
                }
              >
                {(project.projectType.id === 1 ||
                  project.projectType.id === 4) &&
                  formatRupees(project.billingStatusRate)}
                {(project.projectType.id === 2 ||
                  project.projectType.id === 3) &&
                  "N/A"}
              </td>
              <td
                className="align-right"
                style={{
                  backgroundColor: getRevenueAbsorbedStatusColor(
                    project.revenueAbsorbedByExtTickets
                  ),
                }}
              >
                {formatRupees(project.revenueAbsorbedByExtTickets)}
              </td>

              <td
                style={{
                  backgroundColor: getBackgroundColorSatisfaction(
                    project.teamSatisfaction
                  ),
                }}
              >
                {project.teamSatisfaction ? project.teamSatisfaction : "--"}
              </td>
              <td
                style={{
                  backgroundColor: getBackgroundColorSatisfaction(
                    project.customerSatisfaction
                  ),
                }}
              >
                {project.customerSatisfaction
                  ? project.customerSatisfaction
                  : "--"}
              </td>
              {/* <td
              style={{
                backgroundColor: getBackgroundColorSatisfaction(
                  project.teamSatisfaction
                ),
              }}
            >
              {project.teamSatisfaction ? project.teamSatisfaction : "--"}
            </td> */}
              <td>
                {trimAndTooltip(
                  capitalize(renderUserDetails(project.technicalLead)),
                  7
                )}
              </td>
              <td>
                {trimAndTooltip(
                  capitalize(renderUserDetails(project.projectOwner)),
                  7
                )}
              </td>
              <td>
                {trimAndTooltip(
                  capitalize(renderUserDetails(project.deliveryManager)),
                  7
                )}
              </td>
              <td>
                {trimAndTooltip(
                  capitalize(renderUserDetails(project.projectManager)),
                  7
                )}
              </td>
              {!project.subProjects ? (
                <Tooltip title="Edit Project">
                  <td className="edit_button_class">
                    <button
                      className="edit-button"
                      onClick={() =>
                        handleEditClick(project.projectId.toString())
                      }
                    >
                      <FaEdit className="edit-icon" />
                    </button>
                  </td>
                </Tooltip>
              ) : (
                <td></td>
              )}
              {!project.subProjects ? (
                <Tooltip title="Delete" arrow>
                  <td>
                    <FaTrash
                      className="icon"
                      onClick={() => handleOpen(project.projectId)}
                      style={{ cursor: "pointer", color: "red" }}
                    />
                  </td>
                </Tooltip>
              ) : (
                <td></td>
              )}
            </tr>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="confirm-delete-dialog-title"
              aria-describedby="confirm-delete-dialog-description"
            >
              <DialogTitle id="confirm-delete-dialog-title">
                {"Confirm Deletion"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="confirm-delete-dialog-description">
                  Are you sure you want to delete this item?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleClose();
                    handleConfirmDelete();
                  }}
                  color="primary"
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            {/*  for subprojects display  */}

            {expandedProjectId === project.projectId &&
              project.subProjects &&
              project.subProjects.map((sub) => (
                <tr key={sub.projectId}>
                  <td
                    className="fixed"
                    id="sub-project"
                    style={{ cursor: "pointer" }}
                  >
                    {trimAndTooltip(capitalize(sub.name), 7)}
                  </td>
                  <td>{renderField(sub.projectType)}</td>
                  <td>{sub.criticality}</td>
                  <td>
                    {departmentOptions.find(
                      (option) => option.value === sub.departmentId
                    )?.label || "Unknown"}
                  </td>
                  <td
                    style={{
                      backgroundColor: getStatusColor(
                        renderField(sub.projectStatus)
                      ),
                    }}
                  >
                    {renderField(sub.projectStatus)}
                  </td>
                  <td className="align-right">
                    {formatRupees(
                      Number(sub.totalQuote) + Number(sub.totalQuoteQA)
                    )}
                  </td>
                  <td className="align-right">
                    {formatRupees(Number(sub.currentRevenue))}
                  </td>
                  <td
                    className="align-right"
                    style={{
                      backgroundColor: sub.activeSprint ? "transparent" : "red",
                    }}
                    onClick={() => handleProjectAdherenceValues(project.projectId)}
                  >
                    {sub.activeSprint
                      ? Number(sub.processAdherence).toFixed(2)
                      : "No Active Sprint"}
                  </td>
                  {/* <td className="align-right">{sub.plannedEbitda?.toFixed(1)}</td>
                <td className="align-right">{sub.targetEbitda?.toFixed(1)}</td>
                <td className="align-right">{sub.actualEbitda?.toFixed(1)}</td>*/}
                  <td className="align-right">
                    {sub.plannedEbitdaPercent?.toFixed(1)}
                  </td>
                  <td className="align-right">
                    {sub.targetEbitdaPercent?.toFixed(1)}
                  </td>
                  <td
                    className="align-right"
                    style={{
                      backgroundColor: getEbidtaColorStatus(
                        sub.actualEbitdaPercent,
                        sub.targetEbitdaPercent
                      ),
                    }}
                  >
                    {sub.actualEbitdaPercent?.toFixed(1) || "--"}
                  </td>
                  <td className="align-right">{sub.effortDelay?.toFixed(1)}</td>
                  <td className="align-right">
                    {sub.extensionLoggedEfforts?.toFixed(1)}
                  </td>
                  <td className="align-right">
                    {sub.crConsumedEfforts?.toFixed(1)}
                  </td>
                  {/* <td
                  className="align-right"
                  style={{
                    backgroundColor: getEffortDelayStatus(
                      sub.effortsDelayByOverLoggingPercent
                    ),
                  }}
                >
                  {sub.effortsDelayByOverLoggingPercent?.toFixed(1)}
                </td>  */}

                  {/* <td
                  className="align-right"
                  style={{
                    backgroundColor: getEffortDelayStatus(
                      sub.effortsDelayByExtTicketsPercent
                    ),
                  }}
                >
                  {sub.effortsDelayByExtTicketsPercent?.toFixed(1)}
                </td> */}

                  {/* <td className="align-right">{sub.expectedMaxBilling}</td>
                <td className="align-right">{sub.expectedMinBilling}</td> */}
                  <td
                    className="align-right"
                    style={
                      sub.projectType.id === 1 || sub.projectType.id === 4
                        ? {
                            backgroundColor: getBillingStatusColor(
                              sub.billingStatusRate,
                              sub.expectedCurrentMinBilling,
                              sub.expectedCurrentMaxBilling
                            ),
                          }
                        : {}
                    }
                  >
                    {(sub.projectType.id === 1 || sub.projectType.id === 4) &&
                      formatRupees(sub.billingStatusRate)}
                    {(sub.projectType.id === 2 || sub.projectType.id === 3) &&
                      "N/A"}
                  </td>
                  <td
                    className="align-right"
                    style={{
                      backgroundColor: getRevenueAbsorbedStatusColor(
                        sub.revenueAbsorbedByExtTickets
                      ),
                    }}
                  >
                    {formatRupees(sub.revenueAbsorbedByExtTickets)}
                  </td>

                  <td
                    style={{
                      backgroundColor: getBackgroundColorSatisfaction(
                        sub.teamSatisfaction
                      ),
                    }}
                  >
                    {sub.teamSatisfaction ? sub.teamSatisfaction : "--"}
                  </td>
                  <td
                    style={{
                      backgroundColor: getBackgroundColorSatisfaction(
                        sub.customerSatisfaction
                      ),
                    }}
                  >
                    {sub.customerSatisfaction ? sub.customerSatisfaction : "--"}
                  </td>

                  <td>
                    {trimAndTooltip(
                      capitalize(renderUserDetails(sub.technicalLead)),
                      7
                    )}
                  </td>
                  <td>
                    {trimAndTooltip(
                      capitalize(renderUserDetails(sub.projectOwner)),
                      7
                    )}
                  </td>
                  <td>
                    {trimAndTooltip(
                      capitalize(renderUserDetails(sub.deliveryManager)),
                      7
                    )}
                  </td>
                  <td>
                    {trimAndTooltip(
                      capitalize(renderUserDetails(sub.projectManager)),
                      7
                    )}
                  </td>
                  <Tooltip title="Edit Project">
                    <td className="edit_button_class">
                      <button
                        title="Edit Project"
                        className="edit-button"
                        onClick={() =>
                          handleEditClick(sub.projectId.toString())
                        }
                      >
                        <FaEdit className="edit-icon" />
                      </button>
                    </td>
                  </Tooltip>
                </tr>
              ))}
          </React.Fragment>
        ))}
      </tbody>
      <ProcessAdherenceDataModal
        openAdherence={openAdherence}
        handleCloseAdherence={handleCloseAdherence}
        projectAdherenceData={projectAdherenceData}
      />
    </>
  );
};

export default TableBodys;
