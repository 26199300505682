import axios from "axios";
import { ResourceDataDashboard } from "components/CreateResourceForm";
import SummaryDashboard from "components/SummaryDashboard";
import { request } from "http";
import { useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
export interface Project {
  project_id: string;
  name: string;
  jira_project_id_ext: number;
  is_internal: number;
}

export interface DataResponse {
  // "Total Opportunity Lost": BillingData;
  "Opportunity Revenue (Full Time Resource Bench time)": BillingData;
  "Opportunity Revenue (SOW Signed and Work not started)": BillingData;
  "Opportunity Revenue (Net Extensions)": BillingData;
  Watermelon: BillingData;
  "Total Resources (Full time)": BillingData;
  "Billed Resources (Full time)": BillingData;
  BY: BillingData;
  "Non BY Resources": BillingData;
  "Unbilled Resources (Full time)": BillingData;
  "COE & Mandatory Internal Projects": BillingData;
  // "Optional Internal Projects": BillingData;
  Leave: BillingData;
  "No Project assigned": BillingData;
  "Percentage of Unbilled Resources (Full time)": BillingData;
  "Bench Time Hours (Full Time Resources)": BillingData;
  "Bench Time Hours (Interns)": BillingData;
  "Free(Interns)": BillingData;
  "Engaged(Interns)": BillingData;
  "Total Interns": BillingData;
  "Client Satisfaction Score": SatisfactionData;
  "Employee Satisfaction Score": SatisfactionData;
  "Escalation Rate (Starting from June 1st)": BillingData;
  OverBilling: BillingData;
  UnderBilling: BillingData;
}
export interface ProjectAdherenceDataProps{
  tl: ProjectAdherenceDataValueProps,
  pm: ProjectAdherenceDataValueProps,
  po: ProjectAdherenceDataValueProps,
  individual: ProjectAdherenceDataValueProps,
  design: ProjectAdherenceDataValueProps,
}
interface ProjectAdherenceDataValueProps{
  name: string;
  value: number;
}
interface SatisfactionData {
  Design: string;
  Dev: string;
  QA: string;
  "Total (Design + Dev + QA)": string;
}

interface BillingData {
  Design: number;
  Dev: number;
  QA: number;
  "Total (Design + Dev + QA)": number;
}
export interface AssigneeLoggedHours {
  firstName: string;
  totalLoggedTime: number;
  departmentId: string;
  employmentType: string;
}

export interface ProjectData {
  name: string;
  description?: string; // Optional
  projectTypeId: number;
  startDate: Date | null;
  endDate: Date | null;
  totalQuote: number | null;
  totalQuoteQA: number | null;
  totalQuotedEfforts: number | null;
  totalQuotedEffortsQA: number | null;
  jiraProjectIdExt: string;
  leadName: string;
  leadAccountId: string;
  departmentId: number | null;
  projectOwnerId: number | null;
  projectManagerId: number | null;
  technicalLeadId: number | null;
  deliveryManagerId: number | null;
  projectedEndDate: Date | null;
  projectedEfforts: number | null;
  projectedTotalQuote: number | null;
  customerSatisfaction: string | null;
  teamSatisfaction: string | null;
  criticality: string | null;
  sowEndDate: Date | null;
  flcWeekly: number | null;
  flcWeeklyQA: number | null;
  projectStatusId: number;
  jiraLink: string | null;
  expectedMaxBilling: number;
  expectedMinBilling: number;
  totalTargetEfforts: number;
  totalTargetEffortsQA: number;
  billingRate: number;
  billingRateQA: number;
  plannedCalendarEfforts: number;
  revenueAbsorbedByOverLogging: number;
  revenueAbsorbedByExtTickets: number;
}

export interface ProjectRespData {
  projectId: number;
  projectStatusId: number;
  description?: string;
  name: string;
  startDate: string;
  endDate: string;
  totalQuote: number;
  totalQuoteQA: number;
  totalQuotedEfforts: number;
  totalQuotedEffortsQA: number;
  jiraProjectIdExt: number;
  updatedAt: string;
  departmentId: number;
  customerSatisfaction: string;
  teamSatisfaction: string;
  criticality: string;
  flcWeekly: number;
  flcWeeklyQA: number;
  sowEndDate: string;
  jiraLink: string | null;
  billingRate: number;
  billingRateQA: number;
  totalTargetEfforts: number;
  totalTargetEffortsQA: number;
  totalExtensionEfforts: string | null;
  technicalLead: {
    id: number;
    email: string;
    lastName: string | null;
    firstName: string;
  };
  projectOwner: {
    id: number;
    email: string;
    lastName: string | null;
    firstName: string;
  };
  deliveryManager: {
    id: number;
    email: string;
    lastName: string | null;
    firstName: string;
  };
  projectManager: {
    id: number;
    email: string;
    lastName: string | null;
    firstName: string;
  };
  projectType: {
    id: number;
    name: string;
  };
  projectStatus: {
    id: number;
    name: string;
  };
  isCreated: number;
  totalPlannedCost: number;
  plannedEbitda: number;
  totalTargetCost: number;
  targetEbitda: number;
  totalLoggedEfforts: number;
  totalActualCost: number;
  actualEbitda: number;
  expectedMaxBilling: number;
  expectedMinBilling: number;
  expectedCurrentMaxBilling: number;
  expectedCurrentMinBilling: number;
  effortsDelayByOverLogging: number;
  effortsDelayByExtTickets: number;
  billingStatusRate: number;
  billingStatus: string;
  actualEbitdaPercent: number;
  targetEbitdaPercent: number;
  plannedEbitdaPercent: number;
  effortsDelayByOverLoggingPercent: number;
  effortsDelayByExtTicketsPercent: number;
  plannedCalendarEfforts: number;
  revenueAbsorbedByOverLogging: number;
  revenueAbsorbedByExtTickets: number;
  subProjects?: ProjectRespData[];
}
export interface Assignee {
  assignee_id: number;
  first_name: string;
  assignee_jira_account_id_ext: string;
  assignee_email: string;
}

interface CreateProject {
  name: string;
  leadName: string;
  leadAccountId: string;
  description: string;
  projectTypeId: number;
  startDate: Date;
  totalQuote: number;
  totalQuotedEfforts: number;
  jiraProjectIdExt: string;
  technicalLeadId: number;
  projectManagerId: number;
  deliveryManagerId: number;
  projectOwnerId: number;
  departmentId: number;
  customerSatisfaction: string;
  teamSatisfaction: string;
  projectStatusId: number;
  endDate: Date;
}

interface ResourceProject {
  name: string;
  projectId: number;
}

export interface Resource {
  assigneeId?: number;
  firstName: string;
  lastName?: string | null;
  email: string;
  availabilityStatusId: number | null;
  phoneNumber?: string | null;
  designationId?: number | null;
  createdAt?: string;
  updatedAt?: string;
  departmentId: string | null;
  employementType: string;
  freeBy: string | null;
  freeFrom: string | null;
  freeTo: string | null;
  inHouse: number;
  hasJiraAccess: number;
  projects: ResourceProject[] | null;
  specialAccess?: number;
  isValid: boolean;
}

export interface ResourceDetails {
  firstName: string;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  designationId: number | null;
  createdAt: string;
  updatedAt: string;
  createdBy: string | null;
  updatedBy: string | null;
  jiraAccountIdExt: string;
  departmentId: number;
  employementType: string | null;
  availabilityStatusId: number | null;
  freeBy: string | null;
  freeFrom: string | null;
  freeTo: string | null;
  inHouse: number;
  hasJiraAccess: number;
  specialAccess: number;
  assigneeId: number;
  deleted_at: string | null;
  sync: {
    syncAccess: number;
    requestId: number;
  };
}

export interface ProjectRespDataInternal {
  projectId: number;
  projectStatusId: number;
  description?: string;
  name: string;
  startDate: string;
  endDate: string;
  totalQuote: number;
  totalQuoteQA: number;
  totalQuotedEfforts: number;
  totalQuotedEffortsQA: number;
  jiraProjectIdExt: number;
  updatedAt: string;
  departmentId: number;
  customerSatisfaction: string;
  teamSatisfaction: string;
  criticality: string;
  flcWeekly: number;
  flcWeeklyQA: number;
  sowEndDate: string;
  jiraLink: string | null;
  billingRate: number;
  billingRateQA: number;
  totalTargetEfforts: number;
  totalTargetEffortsQA: number;
  totalExtensionEfforts: string | null;
  technicalLead: {
    id: number;
    email: string;
    lastName: string | null;
    firstName: string;
  };
  projectOwner: {
    id: number;
    email: string;
    lastName: string | null;
    firstName: string;
  };
  deliveryManager: {
    id: number;
    email: string;
    lastName: string | null;
    firstName: string;
  };
  projectManager: {
    id: number;
    email: string;
    lastName: string | null;
    firstName: string;
  };
  projectType: {
    id: number;
    name: string;
  };
  projectStatus: {
    id: number;
    name: string;
  };
  isCreated: number;
  totalPlannedCost: number;
  plannedEbitda: number;
  totalTargetCost: number;
  targetEbitda: number;
  totalLoggedEfforts: number;
  totalActualCost: number;
  actualEbitda: number;
  expectedMaxBilling: number;
  expectedMinBilling: number;
  expectedCurrentMaxBilling: number;
  expectedCurrentMinBilling: number;
  effortDelay: number;
  effortDelayPercent: number;
  billingStatusRate: number;
  actualEbitdaPercent: number;
  targetEbitdaPercent: number;
  plannedEbitdaPercent: number;
  crConsumedEffortsPercent: number;
  crPlannedEfforts: number;
  crConsumedEfforts: number;
  currentRevenue: number;
  extensionLoggedEfforts: number;
  plannedCalendarEfforts: number;
  revenueAbsorbedByOverLogging: number;
  revenueAbsorbedByExtTickets: number;
  processAdherence?: number;
  activeSprint?: number;
  subProjects?: ProjectRespDataInternal[];
  processAdherencePercentageData?: ProjectAdherenceDataProps;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_AUTH_URL = process.env.REACT_APP_BASE_AUTH_URL;

// const BASE_URL = "http://localhost:9000/dashboard";
// const BASE_AUTH_URL = "http://localhost:9000";

const ApiUrls = {
  getProjectsList: "get-all-projects-list",
  generateProjectTimesheet: "generate-project-timesheet",
  googleAuthUrl: "google/url",
  downloadTimesheet: "download-timesheet",
  downloadDefaultersTimesheet: "download-defaulters-timesheet",
  downloadPersonWiseTimesheet: "download-person-table",
  downloadProjectWiseTimesheet: "download-project-table",
  syncDB: "project-wise-sync",
  createProject: "project",
  getallassignees: "get-all-assignees",
  project: "project",
  groupProject: "group-project",
  resource: "resource",
  manualAnnualSummary: "annual-summary",
  billing: "billing",
  summary: "summary",
  summaryDashboard: "summary-dashboard",
  assignee: "assignee",
  manualAnnualSatisfaction: "annual-satisfaction",
  revenueLost: "revenue-lost",
  benchTime: "bench-time-logs",
  downloadBenchTime: "download-bench",
  email: "email",
  manageProjectSync: "manual-sync",
  checkProjectInDB: "check-project",
  syncStatus: "sync-status",
  syncRequest: "sync-request",
  validate: "validate",
  dashboard: "dashboard",
  internalSync: "internal-sync",
};

// Clears session data and reloads the page upon session expiration.
export const handleSessionExpired = () => {
  const cookies = new Cookies();
  cookies.remove("accessToken", { path: "/" });
  cookies.remove("email", { path: "/" });
  sessionStorage.removeItem("authToken");
  sessionStorage.removeItem("username");
  sessionStorage.setItem("expired", "expired");
  window.location.href = "/project-iq";
  setTimeout(() => {
    window.location.reload();
  }, 3000);
  return true;
};

// Intercepts axios responses and handles 401 errors due to expired JWT tokens by calling handleSessionExpired().
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.data.status === 401 &&
      error.response.data.message === "jwt expired"
    ) {
      handleSessionExpired();
    }
    return Promise.reject(error);
  }
);

// Retrieves all projects from the server using an authenticated request.
export async function getAllProjects(
  isCreated: number,
  departmentId: number[],
  projectCategoryId: number
): Promise<Project[]> {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  try {
    let url = `${BASE_URL}/${ApiUrls.getProjectsList}`;

    let params = "?";
    if (isCreated === 0 || isCreated === 1) {
      params = params + `isCreated=${isCreated}`;
    }

    if (departmentId.length > 0) {
      params =
        params +
        (params.slice(-1) === "?"
          ? `departmentId=${JSON.stringify(departmentId)}`
          : `&departmentId=${JSON.stringify(departmentId)}`);
    }

    if (projectCategoryId) {
      params =
        params +
        (params.slice(-1) === "?"
          ? `projectCategoryId=${projectCategoryId}`
          : `&projectCategoryId=${projectCategoryId}`);
    }

    if (params) {
      url += params;
    }

    const response = await axios.get<{ data: Project[] }>(url, {
      headers: { Authorization: `${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching projects:", error);
    return [];
  }
}

// Initiates Google OAuth authentication by redirecting the user to the provided authentication URL.
export async function googleAuth() {
  try {
    const response = await axios.get(
      `${BASE_AUTH_URL}/${ApiUrls.googleAuthUrl}`
    );
    const redirectUrl = response.data.message.url;
    window.location.replace(redirectUrl);
  } catch (error) {
    console.error("Error While Logging:", error);
  }
}

// Posts timesheet data to the server for generation, requires authentication token.
export async function postTimesheetData(payload: any): Promise<any> {
  const token = sessionStorage.getItem("authToken");

  if (
    !token ||
    !payload ||
    (payload.fromDate === "" &&
      payload.projectId.length === 0 &&
      payload.toDate === "")
  ) {
    return null;
  }

  try {
    const response = await axios.post(
      `${BASE_URL}/${ApiUrls.generateProjectTimesheet}`,
      payload,
      { headers: { Authorization: `${token}` } }
    );
    // console.log(JSON.stringify(response.data.data));
    return response.data.data;
  } catch (error) {
    // console.log(payload);
    console.error("Error posting timesheet data:", error);
    return null;
  }
}

export async function timesheetDeafaultersData(payload: any): Promise<any> {
  const token = sessionStorage.getItem("authToken");

  if (
    !token ||
    !payload ||
    (payload.fromDate === "" &&
      payload.projectId.length === 0 &&
      payload.toDate === "")
  ) {
    return null;
  }

  try {
    const response = await axios.post(
      `${BASE_URL}/${ApiUrls.assignee}`,
      payload,
      { headers: { Authorization: `${token}` } }
    );
    // console.log(JSON.stringify(response.data.data));
    return response.data.data;
  } catch (error) {
    // console.log(payload);
    console.error("Error posting timesheet data:", error);
    return null;
  }
}

// Initiates the download of a timesheet file from the server, requires authentication token.

export async function downloadTimesheet(
  payload: any,
  selectedTimesheet: string
): Promise<any> {
  const token = sessionStorage.getItem("authToken");
  // console.log(payload)
  if (!token) {
    return null;
  }

  const timesheetApiMapping = {
    Timesheet: ApiUrls.downloadTimesheet,
    ProjectWiseTimesheet: ApiUrls.downloadProjectWiseTimesheet,
    PersonWiseTimesheet: ApiUrls.downloadPersonWiseTimesheet,
    TimeSheetDefaulters: ApiUrls.downloadDefaultersTimesheet,
    BenchTime: ApiUrls.downloadBenchTime,
  };

  function isTimesheetType(
    key: string
  ): key is keyof typeof timesheetApiMapping {
    return key in timesheetApiMapping;
  }

  if (!isTimesheetType(selectedTimesheet)) {
    console.error(`Invalid timesheet type: ${selectedTimesheet}`);
    return null;
  }

  const downloadApiUrl = timesheetApiMapping[selectedTimesheet];

  try {
    let response;

    response = await axios.post(`${BASE_URL}/${downloadApiUrl}`, payload, {
      headers: { Authorization: `${token}` },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    console.error("Error downloading timesheet data:", error);
    return null;
  }
}

// Initiates a database synchronization process with the server, requires authentication token.
export async function syncDB(payload: any): Promise<any> {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return null;
  }

  try {
    const response = await axios.post(
      `${BASE_AUTH_URL}/jira/${ApiUrls.syncDB}`,
      payload,

      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data.data;
  } catch (error) {
    console.error("Error sync DB:", error);
    return null;
  }
}

export async function createProject(data: CreateProject) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    // console.log("project", data);
    const response = await axios.post(
      `${BASE_URL}/${ApiUrls.createProject}`,
      data,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating project", error);
    return null;
  }
}

// Retrieves all assignees from the server using an authenticated request.
export async function getAllAssignees(): Promise<Assignee[]> {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  try {
    const response = await axios.get<{ data: Assignee[] }>(
      `${BASE_URL}/${ApiUrls.getallassignees}`,
      { headers: { Authorization: `${token}` } }
    );
    // console.log(response.data)
    return response.data.data;
  } catch (error) {
    console.error("Error fetching assignees:", error);
    return [];
  }
}

export async function getProjectById(
  project_id: number
): Promise<ProjectData[]> {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  try {
    const response = await axios.get<{ data: ProjectRespData }>(
      `${BASE_URL}/${ApiUrls.project}/${project_id}`,
      {
        headers: { Authorization: `${token}` },
      }
    );

    const project = response.data.data;
    // console.log("planned", project);
    const responseData: ProjectData[] = [
      {
        name: project.name,
        description: project.description || "",
        projectTypeId: project.projectType.id,
        startDate: project.startDate ? new Date(project.startDate) : null,
        endDate: project.endDate ? new Date(project.endDate) : null,
        totalQuote: project.totalQuote ? project.totalQuote : null,
        totalQuoteQA: project.totalQuoteQA ? project.totalQuoteQA : null,
        totalQuotedEfforts: project.totalQuotedEfforts
          ? project.totalQuotedEfforts
          : null,
        jiraProjectIdExt: project.jiraProjectIdExt.toString(),
        leadName: project.technicalLead.firstName,
        leadAccountId: "",
        departmentId: project.departmentId,
        projectOwnerId: project.projectOwner.id,
        projectManagerId: project.projectManager.id,
        technicalLeadId: project.technicalLead.id,
        deliveryManagerId: project.deliveryManager.id,
        projectedEndDate: null,
        projectedEfforts: null,
        projectedTotalQuote: null,
        customerSatisfaction: project.customerSatisfaction,
        teamSatisfaction: project.teamSatisfaction,
        criticality: project.criticality,
        sowEndDate: project.sowEndDate ? new Date(project.sowEndDate) : null,
        flcWeekly: project.flcWeekly ? project.flcWeekly : null,
        projectStatusId: project.projectStatus.id,
        jiraLink: project.jiraLink,
        expectedMaxBilling: project.expectedMaxBilling,
        expectedMinBilling: project.expectedMinBilling,
        totalTargetEfforts: project.totalTargetEfforts,
        billingRate: project.billingRate,
        billingRateQA: project.billingRateQA,
        plannedCalendarEfforts: project.plannedCalendarEfforts,
        revenueAbsorbedByOverLogging: project.revenueAbsorbedByOverLogging,
        revenueAbsorbedByExtTickets: project.revenueAbsorbedByExtTickets,
        flcWeeklyQA: project.flcWeeklyQA ? project.flcWeeklyQA : null,
        totalTargetEffortsQA: project.totalTargetEffortsQA,
        totalQuotedEffortsQA: project.totalQuotedEffortsQA,
      },
    ];

    // console.log(responseData,"response");
    return responseData;
  } catch (error) {
    console.error(`Error fetching project by id: ${project_id}`, error);
    return [];
  }
}

export async function updateProject(data: ProjectData, project_id: number) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    // console.log("project", data);
    const response = await axios.put(
      `${BASE_URL}/${ApiUrls.project}/${project_id}`,
      data,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating project", error);
    return null;
  }
}
export async function deleteProjectById(project_id: number) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    const response = await axios.delete(
      `${BASE_AUTH_URL}/${ApiUrls.dashboard}/${project_id}`,
      {
        headers: { Authorization: `${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(`Error deleting project with id ${project_id}`, error);
    return null;
  }
}

export async function getProjectWiseAssignees(project_id: number) {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  try {
    const response = await axios.get<{ data: Assignee[] }>(
      `${BASE_URL}/${ApiUrls.project}/${project_id}/assignee`,
      { headers: { Authorization: `${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching assignees:", error);
    return [];
  }
}

export async function getProjects(sprintFilter: number): Promise<ProjectRespDataInternal[]> {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  const sprintFilterString = String(sprintFilter);
  try {
    const response = await axios.get<{ data: ProjectRespDataInternal[] }>(
      `${BASE_URL}/${ApiUrls.project}?sprintFilter=${sprintFilterString}`,
      { headers: { Authorization: `${token}` } }
    );
    // console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching projects:", error);
    return [];
  }
}

export async function getProjectsByInternalSync() {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }
  try {
    const URL = `${BASE_AUTH_URL}/${ApiUrls.dashboard}/${ApiUrls.internalSync}`;
    const response = await axios.post<{ data: any }>(
      URL,
      null,
      { headers: { Authorization: `${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching project sync status:", error);
    return [];
  }
}

export async function groupProject(data: any) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    // console.log("payload group project", data);
    const response = await axios.post(
      `${BASE_URL}/${ApiUrls.groupProject}`,
      data,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
    return data;
  } catch (error) {
    console.error("Error while creating sub projects", error);
    return null;
  }
}

//  interface AssigneeLoggedHours {
//   assigneeId: number;
//   totalLoggedTime: number;
//   departmentId: number;
// }

// Use the token in your API request
export async function postBenchTimeData(
  payload: any
): Promise<AssigneeLoggedHours[]> {
  const token = sessionStorage.getItem("authToken");
  // console.log(token, "token");
  if (
    !token ||
    !payload ||
    (!payload.fromDate && payload.projectId.length === 0 && !payload.toDate)
  ) {
    return [];
  }

  try {
    // console.log("gello");
    const response = await axios.post(
      `${BASE_URL}/${ApiUrls.benchTime}`,
      payload,
      { headers: { Authorization: `${token}` } }
    );
    // console.log(response.data, "response");
    return response.data.data;
  } catch (error) {
    console.error("Error posting bench time data:", error);
    return [];
  }
}

export async function getResources(): Promise<Resource[]> {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }
  try {
    const response = await axios.get<{ data: Resource[] }>(
      `${BASE_AUTH_URL}/${ApiUrls.resource}`,
      { headers: { Authorization: `${token}` } }
    );
    // console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching projects:", error);
    return [];
  }
}

export async function getResourceById(
  resource_id: number
): Promise<Resource[]> {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  try {
    const response = await axios.get<{ data: Resource[] }>(
      `${BASE_AUTH_URL}/${ApiUrls.resource}/${resource_id}`,
      {
        headers: { Authorization: `${token}` },
      }
    );

    return response.data.data;
    // console.log("planned", project);
  } catch (error) {
    console.error(`Error fetching resource by id: ${resource_id}`, error);
    return [];
  }
}

export async function createResource(data: any) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    const response = await axios.post(
      `${BASE_AUTH_URL}/${ApiUrls.resource}`,
      data,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error while creating sub projects", error);
    return null;
  }
}

export async function updateResource(
  resource_id: number,
  data: ResourceDataDashboard
) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    const response = await axios.put(
      `${BASE_AUTH_URL}/${ApiUrls.resource}/${resource_id}`,
      data,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating resource", error);
    return null;
  }
}

export async function deleteResourceById(resource_id: number) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    const response = await axios.delete(
      `${BASE_AUTH_URL}/${ApiUrls.resource}/${resource_id}`,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(`Error deleting resource with id ${resource_id}`, error);
    return null;
  }
}



export async function getManualAnnualSummary() {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }
  try {
    const response = await axios.get<{ data: any }>(
      `${BASE_AUTH_URL}/summary/${ApiUrls.manualAnnualSummary}`,
      { headers: { Authorization: `${token}` } }
    );
    // console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching projects:", error);
    return [];
  }
}

export async function updateManualAnnualSummary(annual_id: string, data: any) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    const response = await axios.put(
      `${BASE_AUTH_URL}/${ApiUrls.summary}/${annual_id}`,
      data,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating manual annual summary", error);
    return null;
  }
}

export async function getManualAnnualSummaryById(annual_id: string) {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  try {
    const response = await axios.get<{ data: any }>(
      `${BASE_AUTH_URL}/summary/${ApiUrls.manualAnnualSummary}/${annual_id}`,
      {
        headers: { Authorization: `${token}` },
      }
    );

    return response.data.data;
    // console.log("planned", project);
  } catch (error) {
    console.error(
      `Error fetching manual annual summary by id: ${annual_id}`,
      error
    );
    return [];
  }
}

export async function getSummaryData() {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  try {
    const response = await axios.get<{ data: any }>(
      `${BASE_AUTH_URL}/${ApiUrls.summary}`,
      {
        headers: { Authorization: `${token}` },
      }
    );
    return response.data.data;
    // console.log("planned", project);
  } catch (error) {
    console.error(`Error fetching manual annual summary`, error);
    return [];
  }
}

export async function getManualAnnualSatisfactionById(annual_id: string) {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  try {
    const response = await axios.get<{ data: any }>(
      `${BASE_AUTH_URL}/summary/${ApiUrls.manualAnnualSatisfaction}/${annual_id}`,
      {
        headers: { Authorization: `${token}` },
      }
    );

    return response.data.data;
    // console.log("planned", project);
  } catch (error) {
    console.error(
      `Error fetching manual annual summary by id: ${annual_id}`,
      error
    );
    return [];
  }
}

export async function updateManualAnnualSatisfaction(
  annual_id: string,
  data: any
) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    const response = await axios.put(
      `${BASE_AUTH_URL}/summary/${ApiUrls.manualAnnualSatisfaction}/${annual_id}`,
      data,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating manual annual summary", error);
    return null;
  }
}

export async function getRevenueLostProjectById(project_id: number) {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  try {
    const response = await axios.get<{ data: any }>(
      `${BASE_AUTH_URL}/summary/${ApiUrls.revenueLost}/${project_id}`,
      {
        headers: { Authorization: `${token}` },
      }
    );

    return response.data.data;
    // console.log("planned", project);
  } catch (error) {
    console.error(
      `Error fetching manual annual summary by id: ${project_id}`,
      error
    );
    return [];
  }
}

export async function updateRevenueLostProjectById(
  project_id: number,
  data: any
) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    const response = await axios.put(
      `${BASE_AUTH_URL}/summary/${ApiUrls.revenueLost}/${project_id}`,
      data,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating manual annual summary", error);
    return null;
  }
}

export async function getRevenueLostByFinancialYear(financialYearId: string) {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }

  try {
    const response = await axios.get<{ data: any }>(
      `${BASE_AUTH_URL}/summary/${ApiUrls.revenueLost}/${financialYearId}`,
      {
        headers: { Authorization: `${token}` },
      }
    );

    return response.data.data;
    // console.log("planned", project);
  } catch (error) {
    console.error(
      `Error fetching manual annual summary by id: ${financialYearId}`,
      error
    );
    return [];
  }
}

export async function updateRevenueLostByFinancialYear(
  financialYearId: string,
  data: any
) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    const response = await axios.put(
      `${BASE_AUTH_URL}/summary/${ApiUrls.revenueLost}/${financialYearId}`,
      data,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating manual annual summary", error);
    return null;
  }
}

export async function getResourceByEmailId(
  email: string
): Promise<ResourceDetails> {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return {} as ResourceDetails;
  }

  try {
    const response = await axios.get<{ data: ResourceDetails }>(
      `${BASE_AUTH_URL}/resource/${ApiUrls.email}/${email}`,
      {
        headers: { Authorization: `${token}` },
      }
    );
    return response.data.data;
    // console.log("planned", project);
  } catch (error) {
    console.error(`Error fetching resource by id: ${email}`, error);
    return {} as ResourceDetails;
  }
}

export async function ManualSyncProject(project_id: number | string) {
  const token = sessionStorage.getItem("authToken");
  const email = sessionStorage.getItem("email");
  const payload = {
    email: email,
  };
  if (!token) {
    return null;
  }
  try {
    const response = await axios.post(
      `${BASE_AUTH_URL}/jira/${ApiUrls.manageProjectSync}/${project_id}`,
      payload,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error syncing project", error);
    return null;
  }
}

export async function checkProjectInDB(project_id: number | string) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  try {
    const response = await axios.get(
      `${BASE_AUTH_URL}/jira/${ApiUrls.checkProjectInDB}/${project_id}`,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error syncing project", error);
    return null;
  }
}

//sync status fetching
export async function getSyncStatus(requestId: number) {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }
  try {
    const response = await axios.get<{ data: any }>(
      `${BASE_AUTH_URL}/jira/${ApiUrls.syncStatus}/${requestId}`,
      {
        headers: { Authorization: `${token}` },
      }
    );

    return response.data.data;
    // console.log("planned", project);
  } catch (error) {
    console.error(`Error fetching sync status}`, error);
    return [];
  }
}

// for sync report get sync requests
export async function getSyncRequest(page:number,rowsPerPage:number) {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }
  try {
    const response = await axios.get<{ data: any }>(
      `${BASE_AUTH_URL}/jira/${ApiUrls.syncRequest}`,
      {
        headers: { Authorization: `${token}` },
        params: {
          page: page,
          pageSize: rowsPerPage
        }
      }
    );
    return response.data
    // console.log("planned", project);
  } catch (error) {
    console.error(`Error fetching sync status}`, error);
    return [];
  }
}

export async function getSyncRequestById(requestId: number) {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }
  try {
    const response = await axios.get<{ data: any }>(
      `${BASE_AUTH_URL}/jira/${ApiUrls.syncRequest}/${requestId}`,
      {
        headers: { Authorization: `${token}` },
      }
    );

    return response.data.data;
    // console.log("planned", project);
  } catch (error) {
    console.error(`Error fetching sync status}`, error);
    return [];
  }
}

export async function updateSyncRequestStatusByRequestId(requestId: number) {
  const token = sessionStorage.getItem("authToken");
  if (!token) {
    return null;
  }
  const payload = {
    email: sessionStorage.getItem("email"),
  };
  try {
    const response = await axios.put(
      `${BASE_AUTH_URL}/jira/${ApiUrls.syncRequest}/${requestId}`,
      payload,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error syncing project", error);
    return null;
  }
}

export async function validateResourceDashboard() {
  const token = sessionStorage.getItem("authToken");

  if (!token) {
    return [];
  }
  try {
    const response = await axios.get<{ data: any }>(
      `${BASE_AUTH_URL}/${ApiUrls.resource}/${ApiUrls.validate}`,
      {
        headers: { Authorization: `${token}` },
      }
    );

    return response.data.data;
    // console.log("planned", project);
  } catch (error) {
    console.error(`Error fetching sync status}`, error);
    return [];
  }
}
