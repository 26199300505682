import React, { useEffect, useState, useRef } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FaSearch } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import { getAllProjects } from "common/api/ApiService";
import "./index.scss";

interface Project {
  project_id: string;
  name: string;
  jira_project_id_ext: number;
  is_internal: number;
}

interface SelectComponentProps {
  selectedOptions: Project[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<Project[]>>;
  isDisabled?: boolean;
  clearSearch: boolean;
  setClearSearch: React.Dispatch<React.SetStateAction<boolean>>;
  isInternal?: boolean;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
    position: "relative",
  },
  menuPaper: {
    width: 250,
    height: 450,
    position: "absolute",
    scrollbarWidth: "thin",
  },
  searchContainer: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    padding: theme.spacing(1),
  },
  searchField: {
    width: "100%",
  },
  selectDisabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

function SelectComponent({
  selectedOptions,
  setSelectedOptions,
  isDisabled,
  clearSearch,
  setClearSearch,
  isInternal,
}: SelectComponentProps) {
  const classes = useStyles();
  const [projects, setProjects] = useState<Project[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLogged, setIsLogged] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [orderedProjects, setOrderedProjects] = useState<Project[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    setIsLogged(!!token);
  }, []);

  useEffect(() => {
    if (clearSearch) {
      setSearchQuery("");
      setClearSearch(false);
    }
  }, [clearSearch, setClearSearch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setClearSearch(false);
  };

  const getRelevanceScore = (project: Project, query: string): number => {
    const projectName = project.name.toLowerCase();
    const searchQuery = query.toLowerCase();

    let score = 0;
    if (projectName.startsWith(searchQuery)) {
      score += 3;
    } else if (projectName.includes(searchQuery)) {
      score += 2;
    }

    const queryWords = searchQuery.split(" ");
    queryWords.forEach((word) => {
      if (projectName.includes(word)) {
        score += 1;
      }
    });

    return score;
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    if (value.includes("all")) {
      if (
        selectedOptions.length === projects.length ||
        filteredProjects.length === selectedOptions.length
      ) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(filteredProjects);
      }
    } else {
      const selectedProjectOptions = value
        .map((option) => projects.find((project) => project.name === option))
        .filter((project): project is Project => project !== undefined);

      setSelectedOptions(selectedProjectOptions);
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    const filteredProjectsWithoutSelected = filteredProjects.filter(
      (project) => !selectedOptions.includes(project)
    );
    const updatedFilteredProjects = [
      ...selectedOptions,
      ...filteredProjectsWithoutSelected,
    ];

    setFilteredProjects(updatedFilteredProjects);
  };

useEffect(() => {
  const fetchData = async () => {
    try {
      if (isLogged) {
        const projectsData = await getAllProjects(2, [1, 2], 0);

        // Filter and sort projects by name
        const filteredProjectsData = isInternal
          ? projectsData.filter((project) => project.is_internal === 1)
          : projectsData;

        // Sort the filtered projects by name
        const sortedProjects = filteredProjectsData?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        setProjects(sortedProjects);
        setSelectedOptions(sortedProjects);

        // When opening the select, sort selected and unselected projects
        const orderedProjects = isOpen
          ? sortedProjects
          : [
              ...selectedOptions.filter((project) =>
                sortedProjects.includes(project)
              ),
              ...sortedProjects.filter(
                (project) => !selectedOptions.includes(project)
              ),
            ];

        setOrderedProjects(orderedProjects);

        // Filter and sort projects based on the search query
        const filteredProjects1 = orderedProjects
          .filter((project) =>
            project.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .sort(
            (a, b) =>
              getRelevanceScore(b, searchQuery) -
              getRelevanceScore(a, searchQuery)
          );
        setFilteredProjects(filteredProjects1);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  fetchData();
}, [isLogged, isInternal]);


  useEffect(() => {
    const filteredProjects1 = orderedProjects
      .filter((project) =>
        project.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort(
        (a, b) =>
          getRelevanceScore(b, searchQuery) - getRelevanceScore(a, searchQuery)
      );
    setFilteredProjects(filteredProjects1);
  }, [searchQuery, orderedProjects]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="multiple-select-label">Project Name</InputLabel>
      <Select
        labelId="multiple-select-label"
        multiple
        required
        value={selectedOptions?.map((option) => option.name)}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        renderValue={(selected) => (selected as string[]).join(", ")}
        MenuProps={{
          PaperProps: {
            className: classes.menuPaper,
          },

          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "menu",
          getContentAnchorEl: null,
        }}
      >
        <div className={classes.searchContainer}>
          <TextField
            placeholder="Search..."
            className={classes.searchField}
            value={searchQuery}
            onChange={handleSearchChange}
            onClick={(event) => event.stopPropagation()}
            inputProps={{
              onKeyDown: (e) => {
                e.stopPropagation();
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaSearch />
                </InputAdornment>
              ),
              onClick: (event) => event.stopPropagation(),
            }}
          />
        </div>

        <MenuItem
          value="all"
          classes={{
            root:
              projects?.length > 0 && selectedOptions?.length === projects.length
                ? classes.selectedAll
                : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              checked={
                projects?.length > 0 &&
                selectedOptions?.length === projects.length
              }
              indeterminate={
                selectedOptions?.length > 0 &&
                selectedOptions?.length < projects?.length
              }
            />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </MenuItem>

        {filteredProjects.map((project) => (
          <MenuItem key={project.project_id} value={project.name}>
            <ListItemIcon>
              <Checkbox
                checked={
                  selectedOptions.map((opt) => opt.name).indexOf(project.name) >
                  -1
                }
              />
            </ListItemIcon>
            <ListItemText primary={project.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectComponent;
