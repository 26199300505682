import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Project,
  Resource,
  deleteResourceById,
  getAllProjects,
  getResources,
  validateResourceDashboard,
} from "common/api/ApiService";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "components/DashboardHeader";
import {
  FaEdit,
  FaFilter,
  FaPlus,
  FaSearch,
  FaSort,
  FaSortAlphaDown,
  FaSortAlphaUp,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { IoArrowBack, IoCloseCircleOutline } from "react-icons/io5";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  AvailabilityStatusOptions,
  EmployeementTypeOptions,
  departmentIdOptions,
} from "components/CreateResourceForm";
import { capitalize } from "utils/Capitalize";
import { Padding } from "@mui/icons-material";
import { trimAndTooltip } from "utils/TrimandToolTip";
import { usePeopleDashboard } from "reducers/GlobalState";
import { FILTER_TYPES } from "./constants";
import FilterMenu from "./FilterMenu";
import SortableHeader from "./SortHeader";

interface FilterStatus {
  selectedDepartments: number[];
  selectedAvailabilityStatus: number[];
  selectedProjects: number[];
}

const departmentOptions = [
  { value: 1, label: "Design" },
  { value: 2, label: "Development" },
  { value: 3, label: "QA" },
  { value: 4, label: "PO" },
  { value: 5, label: "PM" },
  { value: 6, label: "DevOps" },
  { value: 7, label: "DM" },
  { value: 8, label: "Accountant" },
  { value: 9, label: "HR" },
  { value: 10, label: "Sales" },
  { value: 11, label: "Marketing" },
  { value: 12, label: "Finance" },
];

const AvailabilityStatusDashbordOptions = [
  { value: 1, label: "Engaged" },
  { value: 2, label: "Training" },
  { value: 3, label: "Free in few days" },
  { value: 4, label: "Bench" },
  { value: 5, label: "On Leave" },
  { value: 6, label: "Free" },
  { value: 7, label: "Leaving Soon" },
  { value: 8, label: "Need Replacement" },
  { value: 9, label: "Assigned" },
  { value: 10, label: "Yet to Onboard" },
  { value: 11, label: "Partially Free" },
  { value: 12, label: "Internal Project" },
  { value: 13, label: "COE" },
  { value: 14, label: "Shadowing" },
];
enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
  Normal = "normal",
}

const employeeTypeOptions = [
  { value: "full-time", label: "Full Time" },
  { value: "intern", label: "Intern" },
];

const accessOptions = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];
interface CreateFormProps {
  selectedResourceId: string;
  setSelectedResourceId: (selectedResourceId: string) => void;
  isResourceEdit: boolean;
  setIsResourceEdit: (isResourceEdit: boolean) => void;
  isResourceSuccessEdit: boolean;
  setIsResourceSuccessEdit: (isResourceSuccessEdit: boolean) => void;
}

const ResourceDashboardTable: React.FC<CreateFormProps> = ({
  selectedResourceId,
  setSelectedResourceId,
  isResourceEdit,
  setIsResourceEdit,
  isResourceSuccessEdit,
  setIsResourceSuccessEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [allResourcesData, setAllResourcesData] = useState<Resource[]>([]);
  const token = sessionStorage.getItem("authToken");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [showConfirmDialogDelete, setShowConfirmDialogDelete] = useState(false);
  const [inHouseFilter, setInHouseFilter] = useState<number | null>(null);
  const [hasJiraAccessFilter, setHasJiraAccessFilter] = useState<number | null>(
    null
  );
  const [departmentFilter, setDepartmentFilter] = useState<number | null>(null);
  const [employmentTypeFilter, setEmploymentTypeFilter] = useState<
    string | null
  >(null);
  const [availabilityStatusFilter, setAvailabilityStatusFilter] = useState<
    string | null | number
  >(null);
  const [projectListFilter, setProjectListFilter] = useState<string | null>(
    null
  );
  const [projectList, setAllProjectsList] = useState<Project[]>([]);
  const [sortField, setSortField] = useState<keyof Resource | null>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Normal);
  const [anchorElements, setAnchorElements] = useState<{
    [key: string]: HTMLElement | null;
  }>({
    anchor: null,
    department: null,
    employment: null,
    projects: null,
  });
  const [filterType, setFilterType] = useState<
    | "inHouse"
    | "hasJiraAccess"
    | "department"
    | "availabilityStatus"
    | "employmentType"
    | "project"
    | null
  >(null);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const peopleDashboardContext = usePeopleDashboard();
  const [searchProjectsText, setSearchProjectsText] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<FilterStatus>({
    selectedDepartments: [],
    selectedAvailabilityStatus: [],
    selectedProjects: []
  });
  if(!peopleDashboardContext) {
    throw new Error("PeopleDashboardContext is not provided");
  }
  const { contextData, setContextData } = peopleDashboardContext;
  const location = useLocation();
  useEffect(() => {
    if (token) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, [token]);

  useEffect(() => {
    setLoading(true);
    const fetchingAssignees = async () => {
      try {
        if(location.state?.isCancelClicked){
          setAllResourcesData(contextData);
        }
        else{
          const resp = await getResources();
          if (resp) {
            resp.map((resource) => {
              if (resource.specialAccess === 1) {
                resource.freeBy = null;
                resource.freeFrom = null;
                resource.freeTo = null;
                resource.departmentId = null;
              }
            });
            setAllResourcesData(resp);
            setContextData(resp);
          } else {
            showAlertMessage("error", "Error while fetching Resource");
          }
        }
       
      } catch (error) {
        console.error("Error while fetching all resources", error);
      } finally {
        setLoading(false);
      }
    };
    fetchingAssignees();
  }, []);

  useEffect(() => {
    const fetchingAllProjects = async () => {
      try {
        const resp = await getAllProjects(2, [1, 2, 4], 3);
        if (resp) {
          setAllProjectsList(resp);
        } else {
          showAlertMessage("error", "Error while fetching Resource");
        }
      } catch (error) {
        console.error("Error while fetching all resources", error);
      }
    };
    fetchingAllProjects();
  }, []);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    type:
      | "inHouse"
      | "hasJiraAccess"
      | "department"
      | "availabilityStatus"
      | "employmentType"
      | "project"
  ) => {
    setSortField(null);
    setSortOrder(SortOrder.Normal);
    setIsSearched(false);

    if(type === "department"){
      // setDepartmentEl(event.currentTarget);
      setAnchorElements((prev)=> ({
        ...prev,
        department: event.currentTarget
      }))
    }
   else if(type === "employmentType"){
      // setEmployementEl(event.currentTarget);
      setAnchorElements((prev)=> ({
        ...prev,
        employment: event.currentTarget
      }))
    }
    else if(type === "project"){
      // setProjectsEl(event.currentTarget);
      setAnchorElements((prev)=> ({
        ...prev,
        projects: event.currentTarget
      }))
    }
    else{
      // setAnchorEl(event.currentTarget);
      setAnchorElements((prev)=> ({
        ...prev,
        anchor: event.currentTarget
      }))
    }
    setFilterType(type);
  };

  const toggleSortOrder = () => {
    setSortOrder((prevSortOrder) => {
      switch (prevSortOrder) {
        case SortOrder.Ascending:
          return SortOrder.Descending;
        case SortOrder.Descending:
          return SortOrder.Normal;
        case SortOrder.Normal:
        default:
          return SortOrder.Ascending;
      }
    });
  };

  const handleMenuClose = () => {
   setAnchorElements((prev)=> ({
        ...prev,
        anchor: null,
        department: null,
        employment: null,
        projects: null
      }))
  };

  const handleFilterChange = (value: number | string | null) => {
    if (filterType === "inHouse") {
      setInHouseFilter(value as number | null);
    } else if (filterType === "hasJiraAccess") {
      setHasJiraAccessFilter(value as number | null);
    } else if (filterType === "department") {
      setDepartmentFilter(value as number | null);
    } else if (filterType === "availabilityStatus") {
      setAvailabilityStatusFilter(value as string | null);
    } else if (filterType === "employmentType") {
      setEmploymentTypeFilter(value as string | null);
    } else if (filterType === "project") {
      setProjectListFilter(value as string | null);
    }
    handleMenuClose();
  };

  const handleClearChange = (value: number | string | null) => {
    if (filterType === "inHouse") {
      setInHouseFilter(null);
    } else if (filterType === "hasJiraAccess") {
      setHasJiraAccessFilter(null);
    } else if (filterType === "department") {
      setDepartmentFilter(null);
      setAnchorElements((prev)=> ({
         ...prev,
        department: null
      }))
    } else if (filterType === "availabilityStatus") {
      setAvailabilityStatusFilter(null);
      setAnchorElements((prev)=> ({
          ...prev,
          anchor: null
        }))
    } else if (filterType === "employmentType") {
      setEmploymentTypeFilter(null);
      setAnchorElements((prev)=> ({  
          ...prev,
          employment: null
        }))
    } else if (filterType === "project") {
      setProjectListFilter(null);
      setAnchorElements((prev)=> ({
          ...prev,
          projects: null
        }))
    }
    handleMenuClose();
  };
  const showAlertMessage = (type: "success" | "error", message: string) => {
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, 2000);
  };

  const handleCreateResource = () => {
    setIsResourceEdit(false);
    setSelectedResourceId("0");
    navigate("/create-resource");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClearInput = (): void => {
    setSearchText(""); // Clear the input field
  };
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsSearched(true);
    setSearchText(event.target.value);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.substr(0, 10).split("-");
    return `${day}/${month}/${year}`;
  };

  const handleEdit = (assigneeId: number | null) => {
    // console.log("clicked edit for assignee", assigneeId);
    if (assigneeId) {
      setSelectedResourceId(assigneeId.toString());
      setIsResourceEdit(true);
      navigate("/create-resource");
    }
  };
  const handleDeletePopUp = (assigneeId: number | null) => {
    if (assigneeId) {
      setSelectedResourceId(assigneeId.toString());
      setShowConfirmDialogDelete(true);
      setIsDeleted(false);
    }
  };

  const handleDelete = async (assigneeId: number | null) => {
    // console.log("clicked delete for assignee", assigneeId);
    if (assigneeId) {
      try {
        const resp = await deleteResourceById(assigneeId);
        if (resp) {
          showAlertMessage("success", "deleted Resource Successfully");
          const updatedResources = filteredData.filter(
            (resource) => resource.assigneeId !== assigneeId
          );
          setFilteredData(updatedResources);
          // console.log(resp);
        } else {
          showAlertMessage("error", "Error while deleting Resource");
        }
      } catch (error) {
        console.error("Error while deleting resources", error);
      } finally {
        setShowConfirmDialogDelete(false);
        setIsDeleted(true);
      }
    }

  };

  const sortData = (
    field: keyof Resource,
    order: SortOrder,
    filteredResources: Resource[]
  ) => {
    const sortedData = [...filteredResources].sort((a, b) => {
      let fieldA: string | Date = a[field] as any;
      let fieldB: string | Date = b[field] as any;

      // Convert to Date object if sorting by date field
      if (field === "freeBy" || field === "freeFrom" || field === "freeTo") {
        fieldA = new Date(fieldA);
        fieldB = new Date(fieldB);
      }

      // Handle null or undefined values
      if (!fieldA && !fieldB) return 0;
      if (!fieldA) return order === SortOrder.Ascending ? -1 : 1;
      if (!fieldB) return order === SortOrder.Ascending ? 1 : -1;

      // Compare dates
      if (fieldA instanceof Date && fieldB instanceof Date) {
        if (order === SortOrder.Ascending) {
          return fieldA.getTime() - fieldB.getTime();
        } else if (order === SortOrder.Descending) {
          return fieldB.getTime() - fieldA.getTime();
        } else {
          return 0;
        }
      } else {
        fieldA = fieldA.toString().toLowerCase();
        fieldB = fieldB.toString().toLowerCase();

        if (order === SortOrder.Ascending) {
          return fieldA.localeCompare(fieldB);
        } else if (order === SortOrder.Descending) {
          return fieldB.localeCompare(fieldA);
        } else {
          return 0;
        }
      }
    });

    return sortedData;
  };
  const [filteredData, setFilteredData] = useState<Resource[]>([]);

  const selectedDepartmentsCount = filterStatus.selectedDepartments.length;
  const selectedAvailabilityStatusCount = filterStatus.selectedAvailabilityStatus.length;
  const selectedProjectsCount = filterStatus.selectedProjects.length;

  const finalFilteredResources = useMemo(() => {
    const filteredResources = allResourcesData.filter((item) => {
      const inHouseMatch =
        inHouseFilter !== null ? item.inHouse === inHouseFilter : true;
      const hasJiraAccessMatch =
        hasJiraAccessFilter !== null
          ? item.hasJiraAccess === hasJiraAccessFilter
          : true;
      const departmentMatch =
      selectedDepartmentsCount > 0
          ? filterStatus.selectedDepartments.includes(Number(item.departmentId))
          : true;
          const availabilityStatusMatch =
          selectedAvailabilityStatusCount
            ? filterStatus.selectedAvailabilityStatus.includes(Number(item.availabilityStatusId))
            : true;        
      const employmentTypeMatch =
        employmentTypeFilter !== null
          ? item.employementType === employmentTypeFilter
          : true;
      const projectMatch =
            selectedProjectsCount
              ? item.projects?.some((project) =>
                  filterStatus.selectedProjects.includes(project.projectId)
                )
              : true;

  
      return (
        inHouseMatch &&
        hasJiraAccessMatch &&
        departmentMatch &&
        availabilityStatusMatch &&
        employmentTypeMatch &&
        projectMatch
      );
    });
  
    const sortedResources = sortField
      ? sortData(sortField, sortOrder, filteredResources)
      : filteredResources;
  
    const finalResult = isSearched
      ? sortedResources.filter(
          (resource) =>
            resource.firstName
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            resource.email.toLowerCase().includes(searchText.toLowerCase())
        )
      : sortedResources;
    setFilteredData(finalResult); 

    return finalResult;
  }, [
    allResourcesData,
    inHouseFilter,
    hasJiraAccessFilter,
    availabilityStatusFilter,
    filterStatus.selectedAvailabilityStatus,
    filterStatus.selectedProjects,
    filterStatus.selectedDepartments, 
    employmentTypeFilter,
    projectListFilter,
    sortField,
    sortOrder,
    isSearched,
    searchText,
  ]);


  const getProjectNames = (projectIds: number[]) => {
    return projectIds
      .map((id) => {
        const project = projectList.find(
          (project) => Number(project.project_id) === id
        );
        return project ? capitalize(project.name) : "--";
      })
      .join(", ");
  };

  const handleSortChange = (field: keyof Resource) => {
    if (field === sortField) {
      toggleSortOrder();
    } else {
      setSortField(field);
      setSortOrder(SortOrder.Ascending);
    }
  };
  const iconStyle = {
    fontSize: "13px",
    color: sortOrder === "normal" ? "inherit" : "orange",
  };


  const handleClearSelection = (filterType: string) => {
    if (filterType === FILTER_TYPES.DEPARTMENT) {
      setFilterStatus({
        ...filterStatus,
        selectedDepartments: []
      })
    } else if (filterType === FILTER_TYPES.AVAILABILITY_STATUS) {
      setFilterStatus({
        ...filterStatus,
        selectedAvailabilityStatus: []
      })
    }
    else if (filterType===FILTER_TYPES.PROJECT){
      setFilterStatus({
        ...filterStatus,
        selectedProjects: []
      })
      setSearchProjectsText("");
    }
  };
  
  const updateFilterStatus =(key: keyof FilterStatus, value:number)=>{
    setFilterStatus((prev:FilterStatus) => {
      return  {
      ...prev,
      [key]: prev[key].includes(value)
        ? prev[key].filter((item) => item !== value)
        : [...prev[key], value],
    }});
  }

  
  const handleSelect = (filterType: string, value: number) => {
    if (filterType === FILTER_TYPES.DEPARTMENT) {
      updateFilterStatus("selectedDepartments", value);
    } else if (filterType === FILTER_TYPES.AVAILABILITY_STATUS) {
      updateFilterStatus("selectedAvailabilityStatus", value);
    }
    else if(filterType===FILTER_TYPES.PROJECT){
      updateFilterStatus("selectedProjects", value);
    }
  };
const handleClearAll = () => {
    setFilterStatus({
      selectedDepartments: [],
      selectedAvailabilityStatus: [],
      selectedProjects: []
    });

    setEmploymentTypeFilter(null);
    setInHouseFilter(null);
    setHasJiraAccessFilter(null);
    setDepartmentFilter(null);
    setAvailabilityStatusFilter(null);
    setProjectListFilter(null);
    setSearchText("");
    setSearchProjectsText("");
    setSortField(null);
    setSortOrder(SortOrder.Normal);
    setIsSearched(false);
}



  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <DashboardHeader />
      {showAlert && (
        <Alert
          severity={alertType}
          className="floating-alert"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setShowAlert(false)}
            >
              <IoCloseCircleOutline fontSize="inherit" />
            </IconButton>
          }
        >
          {alertMessage}
        </Alert>
      )}
      {isLogged && (
        <div>
          <div className="resource-header-container">
            <span
              onClick={() => navigate("/")}
              className="resource-dash-back-icon-dashboard"
            >
              <IoArrowBack size={24} />
            </span>
            <h1>People Dashboard</h1>
            <div className="resource-search-container">
              <button 
              style={{
                visibility: selectedDepartmentsCount > 0 ||
                selectedAvailabilityStatusCount > 0 ||
                selectedProjectsCount > 0 || employmentTypeFilter!== null
                  ? "visible"
                  : "hidden"
              }}
              disabled={
                  !(selectedDepartmentsCount > 0 ||
                    selectedAvailabilityStatusCount > 0 ||
                    selectedProjectsCount > 0 || employmentTypeFilter!== null)
                } 
              onClick={handleClearAll}>Clear All</button>
              <div className="input-container">
                <input
                  type="text"
                  value={searchText}
                  onChange={handleInputChange}
                  placeholder="Search..."
                />
                <FaSearch className="icon search-icon" />
                {searchText && (
                  <FaTimes
                    className="icon clear-icon"
                    onClick={() => handleClearInput()}
                  />
                )}
              </div>
              <Tooltip title="Create Resource">
                <button onClick={handleCreateResource}>
                  <FaPlus />
                </button>
              </Tooltip>
            </div>
          </div>
          <div
            className="resource-table-container"
            style={{ overflowX: "unset" }}
          >
            <TableContainer
              component={Paper}
              style={{
                overflowY: "unset",
                overflowX: "unset",
                border: "1px solid #e0e0e0",
                marginTop: "5px",
              }}
            >
              <Table sx={{
                position:"relative"
              }}>
                <TableHead
                sx={{
                  position: "sticky",
                  top:"80px",
                  backgroundColor: "#f0f0f0",
                  zIndex: 1

                }}>
                  <TableRow>
                    {[
                      "First Name",
                      "Email",
                      "Department",
                      "Employment Type",
                      "Free By",
                      "Projects",
                      "Availability Status",
                      "Edit",
                      "Delete",
                    ].map((header) => (
                      <TableCell
                        key={header}
                        style={{
                          fontWeight: "bold",
                          alignItems: "left",
                          textAlign: "left",
                          padding: "0px",
                          border: "1px solid #e0e0e0",
                          overflowWrap: "break-word",
                          hyphens: "auto",
                          backgroundColor: "#f0f0f0",
                          position: "sticky",
                          top: "0",
                          zIndex: "1",
                        }}
                      >
                        <div className="resource-header-filter-div">
                          {header}
                          {header === "First Name" && (
                            <>
                               <SortableHeader
                                header="First Name"
                                sortField={sortField}
                                sortOrder={sortOrder}
                                currentField="firstName"
                                onSortChange={handleSortChange}
                              />
                            </>
                          )}
                          {header === "Email" && (
                            <>
                              <SortableHeader
                                header="Email"
                                sortField={sortField}
                                sortOrder={sortOrder}
                                currentField="email"
                                onSortChange={handleSortChange}
                              />
                            </>
                          )}
                          {header === "Free By" && (
                            <>
                               <SortableHeader
                                header="Free By"
                                sortField={sortField}
                                sortOrder={sortOrder}
                                currentField="freeBy"
                                onSortChange={handleSortChange}
                              />
                            </>
                          )}
                          {header === "Employment Type" && (
                            <>
                            <FilterMenu
                             header="Employement Type"
                             filterType="employmentType"
                             options={employeeTypeOptions}
                             handleMenuOpen={(e)=>handleMenuOpen(e, "employmentType")}
                             handleMenuClose={handleMenuClose}
                             handleClearSelection={handleClearChange}
                             handleSingleSelect={handleFilterChange}
                             anchorEl={anchorElements.employment}
                             selectedValue={employmentTypeFilter}
                            />
                            </>
                          
                          )}
                          {header === "Projects" && (
                            <FilterMenu
                                header="Project"
                                filterType="project"
                                selectedCount={selectedProjectsCount}
                                options={projectList.map((project) => ({
                                  value: parseInt(project.project_id),
                                  label: project.name
                                }))}
                                filterStatusKey={filterStatus.selectedProjects}
                                handleMenuOpen={(e)=>handleMenuOpen(e, "project")}
                                handleMenuClose={handleMenuClose}
                                handleClearSelection={handleClearSelection}
                                handleSelect={handleSelect}
                                anchorEl={anchorElements.projects} 
                                setSearchProjectsText={setSearchProjectsText}
                                searchProjectsText={searchProjectsText}
                              />
                          )}
                          {header === "Department" && (
                            <FilterMenu
                            header="Department"
                            filterType="department"
                            selectedCount={selectedDepartmentsCount}
                            options={departmentOptions}
                            filterStatusKey={filterStatus.selectedDepartments}
                            handleMenuOpen={(e)=>handleMenuOpen(e, "department")}
                            handleMenuClose={handleMenuClose}
                            handleClearSelection={handleClearSelection}
                            handleSelect={handleSelect}
                            anchorEl={anchorElements.department}
                           
                          />
                          )}
                          {header === "Availability Status" && (
                            <FilterMenu
                            header="Availability Status"
                            filterType="availabilityStatus"
                            selectedCount={selectedAvailabilityStatusCount}
                            options={AvailabilityStatusDashbordOptions}
                            filterStatusKey={filterStatus.selectedAvailabilityStatus}
                            handleMenuOpen={(e)=>handleMenuOpen(e, "availabilityStatus")}
                            handleMenuClose={handleMenuClose}
                            handleClearSelection={handleClearSelection}
                            handleSelect={handleSelect}
                            anchorEl={anchorElements.anchor}
                          />
                          )}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={12} style={{ textAlign: "center" }}>
                        <div className="resource-no-results-found-div">
                          <h3 className="resource-no-results-found">
                            No Results Found
                          </h3>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.assigneeId}>
                        <TableCell
                          className="resource-table-cell"
                          style={{
                            textAlign: "left",
                            maxWidth: "200px",
                            position: "relative",
                          }}
                        >
                          {!row.isValid && (
                            <span
                              style={{
                                display: "inline-block",
                                width: "7px",
                                height: "7px",
                                backgroundColor: "red",
                                borderRadius: "50%",
                                left: "5px",
                                verticalAlign: "middle",
                                position: "absolute",
                                top: "10px",
                              }}
                            />
                          )}
                          {trimAndTooltip(capitalize(row.firstName), 20)}
                        </TableCell>
                        <TableCell
                          className="resource-table-cell"
                          style={{ textAlign: "left", maxWidth: "200px" }}
                        >
                          {trimAndTooltip(row.email || "--", 25)}
                        </TableCell>
                        <TableCell
                          className="resource-table-cell"
                          style={{ textAlign: "left", maxWidth: "70px" }}
                        >
                          {row.specialAccess
                            ? "--"
                            : departmentIdOptions.find(
                                (type) =>
                                  type.value ===
                                  parseInt(row?.departmentId || "")
                              )?.label || "--"}
                        </TableCell>
                        <TableCell
                          className="resource-table-emply-cell"
                          style={{ textAlign: "left" }}
                        >
                          {row.specialAccess
                            ? "--"
                            : EmployeementTypeOptions.find(
                                (type) => type.value === row.employementType
                              )?.label || "--"}
                        </TableCell>
                        <TableCell
                          className="resource-table-cell"
                          style={{
                            textAlign: "right",
                            padding: "0px 4px",
                            maxWidth: "70px",
                          }}
                        >
                          {row.specialAccess
                            ? "--"
                            : row.freeBy
                            ? formatDate(row.freeBy)
                            : "--"}
                        </TableCell>
                        <TableCell
                          className="resource-table-cell"
                          style={{ textAlign: "left", maxWidth: "200px" }}
                        >
                          {row.specialAccess
                            ? "--"
                            : trimAndTooltip(
                                row.projects
                                  ? getProjectNames(
                                      row.projects.map(
                                        (project: any) => project.projectId
                                      )
                                    )
                                  : "--",
                                25
                              )}
                        </TableCell>
                        <TableCell
                          className="resource-table-cell"
                          style={{ textAlign: "left", maxWidth: "70px" }}
                        >
                          {row.specialAccess
                            ? "Engaged"
                            : AvailabilityStatusOptions.find(
                                (type) =>
                                  type.value === row.availabilityStatusId
                              )?.label || "--"}
                        </TableCell>
                        <TableCell
                          className="action-button-class-resource"
                          style={{
                            textAlign: "center",
                            maxWidth: "70px",
                            minWidth: "40px",
                          }}
                        >
                          {!row.specialAccess && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Tooltip title="Edit Resource">
                                <button
                                  className="action-button-resource"
                                  onClick={() =>
                                    handleEdit(row.assigneeId || null)
                                  }
                                  style={{ textAlign: "center" }}
                                >
                                  <FaEdit className="action-icon-resource" />
                                </button>
                              </Tooltip>
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                          className="action-button-class-resource"
                          style={{
                            minWidth: "40px",
                            textAlign: "center",
                            maxWidth: "70px",
                          }}
                        >
                          {!row.specialAccess && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Tooltip title="Delete Resource">
                                <button
                                  className="action-button-resource"
                                  onClick={() =>
                                    handleDeletePopUp(row.assigneeId || null)
                                  }
                                >
                                  <FaTrash className="action-icon-resource" />
                                </button>
                              </Tooltip>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {filteredData.length != 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 30, filteredData.length]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </div>
          <Dialog
            open={showConfirmDialogDelete}
            onClose={() => setShowConfirmDialogDelete(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this resource?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowConfirmDialogDelete(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleDelete(parseInt(selectedResourceId))}
                color="primary"
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default ResourceDashboardTable;
