import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the context type
interface AccessContextType {
  // hasAccess: boolean;
  // setHasAccess: React.Dispatch<React.SetStateAction<boolean>>;
  isLogged: boolean;
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context with default values
const AccessContext = createContext<AccessContextType | undefined>(undefined);

// Create a provider component
export const AccessProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLogged, setIsLogged] = useState<boolean>(false);

  return (
    <AccessContext.Provider value={{ isLogged, setIsLogged }}>
      {children}
    </AccessContext.Provider>
  );
};

// Create a custom hook for easier usage of the context
export const useAccess = () => {
  const context = useContext(AccessContext);
  if (context === undefined) {
    throw new Error('useAccess must be used within an AccessProvider');
  }
  return context;
};
