import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Grid,
  Typography,
  Paper,
  Container,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Autocomplete,
  IconButton,
} from "@mui/material";
import "./index.scss";
import {
  Assignee,
  Project,
  ProjectData,
  getAllAssignees,
  getAllProjects,
  getProjectById,
  updateProject,
} from "common/api/ApiService";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useProjects } from "reducers/GlobalState";

interface FormData {
  name: string;
  description: string;
  projectTypeId: number;
  startDate: Date | null;
  endDate: Date | null;
  totalQuote: number | null;
  totalQuoteQA: number;
  totalQuotedEfforts: number | null;
  totalQuotedEffortsQA: number | null;
  jiraProjectIdExt: string;
  leadName: string;
  leadAccountId: string;
  departmentId: number | null;
  projectOwnerId: number | null;
  projectManagerId: number | null;
  technicalLeadId: number | null;
  deliveryManagerId: number | null;
  projectedEndDate: Date | null;
  projectedEfforts: number | null;
  projectedTotalQuote: number | null;
  customerSatisfaction: string | null;
  teamSatisfaction: string | null;
  criticality: string | null;
  sowEndDate: Date | null;
  flcWeekly: number;
  flcWeeklyQA: number;
  projectStatusId: number;
  jiraLink: string;
  billingRate: number;
  billingRateQA: number;
  expectedMinBilling: number;
  expectedMaxBilling: number;
  totalTargetEfforts: number;
  totalTargetEffortsQA: number;
  plannedCalendarEfforts: number;
  revenueAbsorbedByOverLogging: number;
  revenueAbsorbedByExtTickets: number;
}

const steps = [
  " Project Basic Details",
  "Team Members",
  "Financial Details",
  "Effort & Calendar Details",
];

const projectTypeOptions = [
  { value: 0, label: "Select Project Type", disabled: true },
  { value: 1, label: "T&M" },
  { value: 2, label: "Fixed" },
  { value: 3, label: "T&M (Cap)" },
  { value: 4, label: "Retainer" },
];
const gradeOptions = [
  { value: "", label: "Select Grade", disabled: true },
  { value: "A+", label: "A+" },
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
];

const criticalOptions = [
  { value: "Diamond", label: "Diamond" },
  { value: "Platinum", label: "Platinum" },
  { value: "Premium", label: "Premium" },
];

interface CreateFormProps {
  selectedProjectId: string;
  setSelectedProjectId: (projectId: string) => void;
  isEdit: boolean;
  setIsEdit: (isEdit: boolean) => void;
  isSuccessEdit: boolean;
  setIsSuccessEdit: (isSuccessEdit: boolean) => void;
}
const initialFormState: FormData = {
  name: "",
  leadName: "",
  leadAccountId: "",
  description: "",
  projectTypeId: 0,
  startDate: null,
  endDate: null,
  totalQuote: 0,
  totalQuoteQA: 0,
  totalQuotedEfforts: 0,
  totalTargetEffortsQA: 0,
  jiraProjectIdExt: "",
  departmentId: 0,
  projectOwnerId: -1,
  projectManagerId: -1,
  technicalLeadId: -1,
  deliveryManagerId: -1,
  projectedEndDate: null,
  projectedEfforts: null,
  projectedTotalQuote: null,
  customerSatisfaction: "",
  teamSatisfaction: "",
  criticality: "",
  sowEndDate: null,
  flcWeekly: 0,
  flcWeeklyQA: 0,
  projectStatusId: 0,
  jiraLink: "",
  expectedMinBilling: 0,
  expectedMaxBilling: 0,
  totalTargetEfforts: 0,
  totalQuotedEffortsQA: 0,
  plannedCalendarEfforts: 0,
  billingRate: 0,
  billingRateQA: 0,
  revenueAbsorbedByOverLogging: 0,
  revenueAbsorbedByExtTickets: 0,
};

const isStepValid = (step: number, formData: FormData) => {
  switch (step) {
    case 0:
      // return (
      //   formData.name !== "" &&
      //   formData.jiraProjectIdExt !== "" &&
      //   formData.departmentId !== 0 &&
      //   formData.criticality !== ""
      // );
      return true;
    case 1:
      // return (
      //   formData.projectTypeId !== 0 &&
      //   formData.projectStatusId !== 0 &&
      //   formData.totalQuote !== 0 &&
      //   formData.totalQuotedEfforts !== 0 &&
      //   formData.flcWeekly !== 0
      // );
      return true;
    case 2:
      return true;
    case 3:
      return true;
    default:
      return false;
  }
};

const CreateProjectForm: React.FC<CreateFormProps> = ({
  selectedProjectId,
  setSelectedProjectId,
  isEdit,
  setIsEdit,
  isSuccessEdit,
  setIsSuccessEdit,
}) => {
  const [assigneeData, setAssigneeData] = useState<Assignee[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [projects, setProjects] = useState<Project[]>([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDateError, setShowDateError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [projectsList, setProjectsList] = useState<ProjectData[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const projectStatusOptions = [
    { label: "Select Project Status", value: 0, disabled: true },
    { label: "Not started", value: 1 },
    { label: "In progress", value: 2 },
    { label: "Hold", value: 3 },
    { label: "Closed", value: 4 },
  ];
  const departmentOptions = [
    { label: "Select Department Name", value: 0, disabled: true },
    { label: "Design", value: 1 },
    { label: "Development", value: 2 },
    // { label: "Design + Development", value: 3 },
  ];

  const showAlertMessage = (type: "success" | "error", message: string) => {
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, 2000);
  };
  useEffect(() => {
    const fetchAssigneeData = async () => {
      try {
        const data = await getAllAssignees();
        const naAssignee: Assignee = {
          assignee_id: 0,
          first_name: "N/A",
          assignee_jira_account_id_ext: "N/A",
          assignee_email: "N/A",
        };
        data.unshift(naAssignee);
        setAssigneeData(data);
      } catch (error) {
        showAlertMessage("error", "Error while fetching assignee data");
        console.error("Error while fetching assignee data", error);
      }
    };
    fetchAssigneeData();
  }, []);

  useEffect(() => {
    if (selectedProjectId !== "" && isEdit && selectedProjectId !== "0") {
      const fetchProjectList = async () => {
        setLoading(true);
        try {
          const data = await getProjectById(parseInt(selectedProjectId));
          setProjectsList(data);
          const selectedProject = data[0];

          // console.log(selectedProject);
          if (selectedProject) {
            setFormData({
              ...initialFormState,
              name: selectedProject.name || initialFormState.name,
              jiraProjectIdExt:
                selectedProject.jiraProjectIdExt ||
                initialFormState.jiraProjectIdExt,
              leadName: selectedProject.leadName || initialFormState.leadName,
              leadAccountId:
                selectedProject.leadAccountId || initialFormState.leadAccountId,
              description:
                selectedProject.description || initialFormState.description,
              projectTypeId:
                selectedProject.projectTypeId || initialFormState.projectTypeId,
              startDate: selectedProject.startDate
                ? new Date(selectedProject.startDate)
                : initialFormState.startDate,
              endDate: selectedProject.endDate
                ? new Date(selectedProject.endDate)
                : initialFormState.endDate,
              totalQuote:
                Number(selectedProject.totalQuote) ||
                Number(initialFormState.totalQuote),
              totalQuoteQA:
                Number(selectedProject.totalQuoteQA) ||
                Number(initialFormState.totalQuoteQA),
              totalQuotedEfforts:
                Number(selectedProject.totalQuotedEfforts) ||
                Number(initialFormState.totalQuotedEfforts),
              technicalLeadId: selectedProject.technicalLeadId || 0,
              projectManagerId: selectedProject.projectManagerId || 0,
              deliveryManagerId: selectedProject.deliveryManagerId || 0,
              projectOwnerId: selectedProject.projectOwnerId || 0,
              departmentId:
                selectedProject.departmentId || initialFormState.departmentId,
              customerSatisfaction:
                selectedProject.customerSatisfaction ||
                initialFormState.customerSatisfaction,
              teamSatisfaction:
                selectedProject.teamSatisfaction ||
                initialFormState.teamSatisfaction,
              projectStatusId:
                selectedProject.projectStatusId ||
                initialFormState.projectStatusId,
              criticality:
                selectedProject.criticality || initialFormState.criticality,
              sowEndDate: selectedProject.sowEndDate
                ? new Date(selectedProject.sowEndDate)
                : initialFormState.sowEndDate,

              flcWeekly:
                Number(selectedProject.flcWeekly) ||
                Number(initialFormState.flcWeekly),
              totalTargetEfforts:
                Number(selectedProject.totalTargetEfforts) ||
                Number(initialFormState.totalTargetEfforts),
              expectedMinBilling:
                Number(selectedProject.expectedMinBilling) ||
                Number(initialFormState.expectedMinBilling),
              expectedMaxBilling:
                Number(selectedProject.expectedMaxBilling) ||
                Number(initialFormState.expectedMaxBilling),
              jiraLink:
                String(selectedProject.jiraLink) ||
                String(initialFormState.jiraLink),
              billingRate:
                Number(selectedProject.billingRate) ||
                Number(initialFormState.billingRate),
              billingRateQA:
                Number(selectedProject.billingRateQA) ||
                Number(initialFormState.billingRateQA),
              plannedCalendarEfforts:
                Number(selectedProject.plannedCalendarEfforts) ||
                Number(initialFormState.plannedCalendarEfforts),
              totalQuotedEffortsQA:
                Number(selectedProject.totalQuotedEffortsQA) ||
                Number(initialFormState.totalQuotedEffortsQA),
              totalTargetEffortsQA:
                Number(selectedProject.totalTargetEffortsQA) ||
                Number(initialFormState.totalTargetEffortsQA),
              flcWeeklyQA:
                Number(selectedProject.flcWeeklyQA) ||
                Number(initialFormState.flcWeeklyQA),
            });
          }
          // console.log("formdataedited", data);
        } catch (error) {
          showAlertMessage("error", "Error while fetching project data");
          console.error("Error while fetching project data", error);
        } finally {
          setLoading(false);
        }
      };
      fetchProjectList();
    }
  }, [selectedProjectId]);

  useEffect(() => {
    if (selectedProjectId !== "0" && !isEdit && selectedProjectId !== "") {
      const fetchProjectWiseData = async () => {
        try {
          const data = await getProjectById(parseInt(selectedProjectId));
          setProjectsList(data);
          setFormData((prevState) => ({
            ...prevState,
            name: data[0].name,
            jiraProjectIdExt: data[0].jiraProjectIdExt,
          }));
          // console.log(data);
        } catch (error) {
          showAlertMessage("error", "Error while fetching project data");
          console.error("Error while fetching project data", error);
        }
      };
      fetchProjectWiseData();
    }
  }, [selectedProjectId]);

  useEffect(() => {
    const fetchProjectList = async () => {
      try {
        if(!isEdit){
        const data = await getAllProjects(0, [1, 2], 0);
        setProjects(data);
        }
      } catch (error) {
        console.error("Error while fetching project data", error);
      }
    };
    fetchProjectList();
  }, []);

  const handleNext = () => {
    if (isStepValid(activeStep, formData)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let updatedValue: string | number | Date = value;
    // console.log(name);
    if (name === "name") {
      // console.log(name);
      if (!name) {
        // console.log(name);
        setFormData((prevState) => ({
          ...prevState,
          name: projectsList[0].name,
          jiraProjectIdExt: projectsList[0].jiraProjectIdExt,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          name: "",
          jiraProjectIdExt: "0",
        }));
      }
    } else if (
      name === "totalQuotedEfforts" ||
      name === "totalQuote" ||
      name === "flcWeekly" ||
      name === "billingRate" ||
      name === "expectedMinBilling" ||
      name === "expectedMaxBilling" ||
      name === "billingRateQA" ||
      name === "totalTargetEfforts" ||
      name === "totalQuoteQA" ||
      name === "totalQuotedEffortsQA" ||
      name === "flcWeeklyQA" ||
      name === "totalTargetEffortsQA"
    ) {
      updatedValue =
        value === "" || isNaN(Number(value)) ? "" : parseFloat(value);
    } else if (
      name === "projectManagerId" ||
      name === "technicalLeadId" ||
      name === "productOwnerId" ||
      name === "projectStatusId" ||
      name === "projectTypeId" ||
      name === "deliveryManagerId" ||
      name === "flcWeekly" ||
      name === "totalTargetEfforts" ||
      name === "plannedCalendarEfforts"
    ) {
      updatedValue =
        value === "" || isNaN(Number(value)) ? "" : parseInt(value, 10);
    } else if (
      name === "startDate" ||
      name === "endDate" ||
      name === "sowEndDate"
    ) {
      const dateValue = value.toString();
      const isValidDate = !isNaN(Date.parse(dateValue));
      updatedValue = isValidDate ? dateValue : initialFormState[name] || "";
      // console.log(updatedValue);
      if (name === "startDate") {
        const startDate = new Date(value);
        const endDate = new Date(formData.endDate ?? new Date());
        if (isNaN(startDate.getTime())) {
          setShowDateError(true);
          return;
        } else {
          setShowDateError(false);
        }
        if (
          formData.endDate &&
          endDate &&
          !isNaN(endDate.getTime()) &&
          startDate > endDate
        ) {
          showAlertMessage(
            "error",
            "start Date should not be greater than end date"
          );
          return;
        }
      } else if (name === "endDate") {
        const endDate = new Date(value);
        const startDate = new Date(formData.startDate ?? new Date());
        if (isNaN(endDate.getTime())) {
          showAlertMessage("error", "end Date should be a valid date");
          return;
        }
        if (
          formData.startDate &&
          startDate &&
          !isNaN(startDate.getTime()) &&
          endDate < startDate
        ) {
          showAlertMessage(
            "error",
            `${name} should not be less than start date`
          );
          return;
        }
      }
    }
    // else if (name === "leadName") {
    //   const selectedLeadName = value;
    //   console.log("here");
    //   const selectedLead = projectAssignees.find(
    //     (lead) => lead.first_name === selectedLeadName
    //   );
    //   if (selectedLead) {
    //     setFormData((prevState) => ({
    //       ...prevState,
    //       leadName: selectedLead.first_name,
    //       leadAccountId: selectedLead.assignee_jira_account_id_ext,
    //     }));
    //   }
    //   console.log("here", formData);
    // }
    else if (name === "expectedMinBilling" || name === "expectedMaxBilling") {
      updatedValue =
        value === "" || isNaN(Number(value)) ? "" : parseInt(value, 10);
      if (
        formData.expectedMaxBilling !== 0 &&
        formData.expectedMinBilling !== 0 &&
        formData.expectedMinBilling > formData.expectedMaxBilling
      ) {
        showAlertMessage(
          "error",
          "Expected Min Billing Rate should not be greater than Expected Max Billing Rate"
        );
      }
    }

    const updatedFormData = {
      ...formData,
      [name]: updatedValue,
    };
    setFormData(updatedFormData);
  };

  const handleSubmit = async () => {
    try {
      handleFormSubmit();
    } catch (error) {
      throw new Error("Error at creating project");
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData(initialFormState);
  };

  const handleContinue = () => {
    setShowConfirmDialog(false);
    // console.log(formData);
    handleFormSubmit();
    setTimeout(() => {
      navigate("/project-iq/dashboard");
    }, 2000);
  };

  const assigneeOptions = [
    { assignee_id: 0, first_name: "Select an assignee" },
    ...assigneeData,
  ];

  const handleFormSubmit = async () => {
    const formattedData = {
      ...formData,
      startDate: new Date(formData.startDate ?? new Date()),
      endDate: new Date(formData.endDate ?? new Date()),
      jiraProjectIdExt: formData.jiraProjectIdExt.toString(),
      deliveryManagerId:
        formData.deliveryManagerId !== -1 ? formData.deliveryManagerId : 0,
      technicalLeadId:
        formData.technicalLeadId !== -1 ? formData.technicalLeadId : 0,
      projectManagerId:
        formData.projectManagerId !== -1 ? formData.projectManagerId : 0,
      projectOwnerId:
        formData.projectOwnerId !== -1 ? formData.projectOwnerId : 0,
      billingRate:
        formData.billingRate === 0 ? 0 : Number(formData.billingRate),
      billingRateQA: isNaN(Number(formData.billingRateQA))
        ? 0
        : Number(formData.billingRateQA),
      flcWeekly: isNaN(Number(formData.flcWeekly))
        ? 0
        : Number(formData.flcWeekly),
      flcWeeklyQA: isNaN(Number(formData.flcWeeklyQA))
        ? 0
        : Number(formData.flcWeeklyQA),
      totalQuote: isNaN(Number(formData.totalQuote))
        ? 0
        : Number(formData.totalQuote),
      totalQuoteQA: isNaN(Number(formData.totalQuoteQA))
        ? 0
        : Number(formData.totalQuoteQA),
      totalQuotedEfforts: isNaN(Number(formData.totalQuotedEfforts))
        ? 0
        : Number(formData.totalQuotedEfforts),
      totalQuotedEffortsQA: isNaN(Number(formData.totalQuotedEffortsQA))
        ? 0
        : Number(formData.totalQuotedEffortsQA),
      totalTargetEfforts: isNaN(Number(formData.totalTargetEfforts))
        ? 0
        : Number(formData.totalTargetEfforts),
      totalTargetEffortsQA: isNaN(Number(formData.totalTargetEffortsQA))
        ? 0
        : Number(formData.totalTargetEffortsQA),
      expectedMinBilling: isNaN(Number(formData.expectedMinBilling))
        ? 0
        : Number(formData.expectedMinBilling),
      expectedMaxBilling: isNaN(Number(formData.expectedMaxBilling))
        ? 0
        : Number(formData.expectedMaxBilling),
      plannedCalendarEfforts: isNaN(Number(formData.plannedCalendarEfforts))
        ? 0
        : Number(formData.plannedCalendarEfforts),
    };
    // console.log("formatted", formattedData);
    if (!formData.jiraProjectIdExt) {
      showAlertMessage("error", "Atleast  Project name to be Selected ");
      return;
    }
    // console.log("formatted", formattedData);
    try {
      // await createProject(formattedData);

      const resp = await updateProject(
        formattedData,
        parseInt(selectedProjectId)
      );
      // console.log(resp);
      // const resp = true;
      if (resp) {
        if (isEdit) showAlertMessage("success", "Project Updated successfully");
        else showAlertMessage("success", "Project Created successfully");
        // handleReset();
        setTimeout(() => {
          navigate("/project-iq/dashboard");
        }, 2000);
      } else {
        if (isEdit) showAlertMessage("error", "Error while updating project");
        else showAlertMessage("error", "Error while creating project");
      }
      setIsEdit(false);
      setSelectedProjectId("0");
    } catch (error) {
      if (isEdit) showAlertMessage("error", "Error while updating project");
      else showAlertMessage("error", "Error while creating project");
      console.error("error while creating project");
    }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (showDateError) {
      timeoutId = setTimeout(() => {
        setShowDateError(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showDateError]);

  const formatDate = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleCloseForm = () => {
    setSelectedProjectId("0");
    setIsEdit(false);
    setFormData(initialFormState);
    navigate("/project-iq/dashboard", {state : {isCancelClicked: true}});
  };
  const renderStepContent = (step: number) => {

    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {isEdit ? (
                <TextField
                  label="Project Name"
                  fullWidth
                  value={formData.name}
                  disabled
                  className="text-field"
                  InputProps={{
                    style: {
                      color: formData.name === "" ? "gray" : "inherit",
                      fontFamily: "sans-serif",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ) : (
                <Autocomplete
                renderOption={(props, option) => (
                  <li {...props} key={option.project_id}>
                    {option.name}
                  </li>
                )}
                  options={projects}
                  getOptionLabel={(option) => option.name}
                  value={
                    projects.find(
                      (project) => project.name === formData.name
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setFormData((prevState) => ({
                      ...prevState,             
                      name: newValue?.name || "",
                    }));

                    setSelectedProjectId(newValue?.project_id.toString() || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Name"
                      fullWidth
                      placeholder="Select Project"
                      className="text-field"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          color: formData.name === "" ? "gray" : "inherit",
                          fontFamily: "sans-serif",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="JIRA Project ID Ext"
                name="jiraProjectIdExt"
                placeholder="jira Project Id Ext"
                disabled
                value={
                  selectedProjectId ? projectsList[0]?.jiraProjectIdExt : 0
                }
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color:
                      formData.jiraProjectIdExt === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                placeholder="Enter Description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color: formData.description === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Project Type"
                name="projectTypeId"
                value={formData.projectTypeId}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color:
                      formData.projectTypeId === 0
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              >
                {projectTypeOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Criticality"
                name="criticality"
                placeholder="Select Criticality for Project"
                value={formData.criticality}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color:
                      formData.criticality === ""
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {criticalOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                select
                label="Department"
                name="departmentId"
                value={formData.departmentId}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  style: {
                    color:
                      formData.departmentId === 0
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
                className="text-field"
              >
                {departmentOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Jira Link"
                name="jiraLink"
                placeholder="Enter Jira Project Link"
                value={formData.jiraLink}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color: formData.jiraLink === "" ? "gray" : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
             renderOption={(props, option) => (
              <li {...props} key={option.assignee_id}>
                {option.first_name}
              </li>
            )}
                options={assigneeData}
                getOptionLabel={(option) => option.first_name  || ""}
                value={
                  assigneeData.find(
                    (assignee) =>
                      assignee.assignee_id === formData.technicalLeadId
                  ) || null
                }
                onChange={(event, newValue) => {

                  setFormData((prevState) => ({
                    ...prevState,
                    technicalLeadId: newValue?.assignee_id || 0,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Technical Lead"
                    placeholder="Select Technical Lead"
                    fullWidth
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
               renderOption={(props, option) => (
                <li {...props} key={option.assignee_id}>
                  {option.first_name}
                </li>
              )}
                options={assigneeData}
                getOptionLabel={(option) => option.first_name  || ""}
                value={
                  assigneeData.find(
                    (assignee) =>
                      assignee.assignee_id === formData.projectOwnerId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    projectOwnerId: newValue?.assignee_id || 0,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Product Owner"
                    placeholder="Select Product Owner"
                    fullWidth
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
               renderOption={(props, option) => (
                <li {...props} key={option.assignee_id}>
                  {option.first_name}
                </li>
              )}
                options={assigneeData}
                getOptionLabel={(option) => option.first_name  || ""}
                value={
                  assigneeData.find(
                    (assignee) =>
                      assignee.assignee_id === formData.projectManagerId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    projectManagerId: newValue?.assignee_id || 0,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project Manager"
                    placeholder="Select Project Manager"
                    fullWidth
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
               renderOption={(props, option) => (
                <li {...props} key={option.assignee_id}>
                  {option.first_name}
                </li>
              )}
                options={assigneeData}
                getOptionLabel={(option) => option.first_name  || ""}
                value={
                  assigneeData.find(
                    (assignee) =>
                      assignee.assignee_id === formData.deliveryManagerId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    deliveryManagerId: newValue?.assignee_id || 0,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Delivery Manager"
                    placeholder="Select Delivery Manager"
                    fullWidth
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Project Status"
                name="projectStatusId"
                value={formData.projectStatusId}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color:
                      formData.projectStatusId === 0
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              >
                {projectStatusOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            {(formData.departmentId === 0 || formData.departmentId === 1) &&
              (formData.projectTypeId === 1 ||
                formData.projectTypeId === 4) && (
                <Grid item xs={6}>
                  <TextField
                    label="Billing Rate (₹)"
                    name="billingRate"
                    value={formData.billingRate}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    className="text-field"
                    InputProps={{
                      style: {
                        color:
                          formData.billingRate === 0
                            ? "rgb(199, 198, 198)"
                            : "inherit",
                        fontFamily: "sans-serif",
                      },
                    }}
                  />
                </Grid>
              )}
            {formData.departmentId === 2 &&
              (formData.projectTypeId === 1 ||
                formData.projectTypeId === 4) && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      label="Billing Rate dev(₹)"
                      name="billingRate"
                      value={formData.billingRate}
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      className="text-field"
                      InputProps={{
                        style: {
                          color:
                            formData.billingRate === 0
                              ? "rgb(199, 198, 198)"
                              : "inherit",
                          fontFamily: "sans-serif",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Billing Rate qa(₹)"
                      name="billingRateQA"
                      value={formData.billingRateQA}
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      className="text-field"
                      InputProps={{
                        style: {
                          color:
                            formData.billingRateQA === 0
                              ? "rgb(199, 198, 198)"
                              : "inherit",
                          fontFamily: "sans-serif",
                        },
                      }}
                    />
                  </Grid>
                </>
              )}

            {(formData.departmentId === 0 || formData.departmentId === 1) && (
              <Grid item xs={6}>
                <TextField
                  label="FLC Weekly (₹)"
                  name="flcWeekly"
                  value={formData.flcWeekly || ""}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  className="text-field"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      color:
                        formData.flcWeekly === 0
                          ? "rgb(199, 198, 198)"
                          : "inherit",
                      fontFamily: "sans-serif",
                    },
                  }}
                />
              </Grid>
            )}

            {formData.departmentId === 2 && (
              <>
                <Grid item xs={6}>
                  <TextField
                    label="FLC Weekly Dev(₹)"
                    name="flcWeekly"
                    value={formData.flcWeekly}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        color:
                          formData.flcWeekly === 0
                            ? "rgb(199, 198, 198)"
                            : "inherit",
                        fontFamily: "sans-serif",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="FLC Weekly QA(₹)"
                    name="flcWeeklyQA"
                    value={formData.flcWeeklyQA}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        color:
                          formData.flcWeeklyQA === 0
                            ? "rgb(199, 198, 198)"
                            : "inherit",
                        fontFamily: "sans-serif",
                      },
                    }}
                  />
                </Grid>
              </>
            )}

            {(formData.departmentId === 0 || formData.departmentId === 1) &&
              (formData.projectTypeId === 2 ||
                formData.projectTypeId === 3) && (
                <Grid item xs={6}>
                  <TextField
                    label="Planned Revenue (₹)"
                    name="totalQuote"
                    value={formData.totalQuote}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        color:
                          formData.totalQuote === 0
                            ? "rgb(199, 198, 198)"
                            : "inherit",
                        fontFamily: "sans-serif",
                      },
                    }}
                  />
                </Grid>
              )}
            {formData.departmentId === 2 &&
              (formData.projectTypeId === 2 ||
                formData.projectTypeId === 3) && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      label="Planned Revenue Dev (₹)"
                      name="totalQuote"
                      value={formData.totalQuote}
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      className="text-field"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        style: {
                          color:
                            formData.totalQuote === 0
                              ? "rgb(199, 198, 198)"
                              : "inherit",
                          fontFamily: "sans-serif",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Planned Revenue QA (₹)"
                      name="totalQuoteQA"
                      value={formData.totalQuoteQA}
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      className="text-field"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        style: {
                          color:
                            formData.totalQuoteQA === 0
                              ? "rgb(199, 198, 198)"
                              : "inherit",
                          fontFamily: "sans-serif",
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
            <Grid item xs={6}>
              <TextField
                label="SOW Expiry Date"
                name="sowEndDate"
                value={
                  formData.sowEndDate instanceof Date
                    ? formData.sowEndDate.toISOString().split("T")[0]
                    : initialFormState.sowEndDate || ""
                }
                onChange={handleChange}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                className="text-field"
                InputProps={{
                  style: {
                    color:
                      formData.sowEndDate === null
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            {(formData.projectTypeId === 1 || formData.projectTypeId === 4) && (
              <>
                <Grid item xs={6}>
                  <TextField
                    label="Expected MinBilling/Month"
                    name="expectedMinBilling"
                    value={formData.expectedMinBilling}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        color:
                          formData.expectedMinBilling === 0
                            ? "rgb(199, 198, 198)"
                            : "inherit",
                        fontFamily: "sans-serif",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Expected MaxBilling/Month"
                    name="expectedMaxBilling"
                    value={formData.expectedMaxBilling}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        color:
                          formData.expectedMaxBilling === 0
                            ? "rgb(199, 198, 198)"
                            : "inherit",
                        fontFamily: "sans-serif",
                      },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        );
      case 3:
        return (
          <Grid container spacing={2}>
            {(formData.departmentId === 0 || formData.departmentId === 1) && (
              <Grid item xs={6}>
                <TextField
                  label="Planned Effort Weeks"
                  name="totalQuotedEfforts"
                  value={formData.totalQuotedEfforts || 0}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  className="text-field"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      color:
                        formData.totalQuotedEfforts === 0
                          ? "rgb(199, 198, 198)"
                          : "inherit",
                      fontFamily: "sans-serif",
                    },
                  }}
                />
              </Grid>
            )}
            {formData.departmentId === 2 && (
              <>
                <Grid item xs={6}>
                  <TextField
                    label="Planned Effort Weeks Dev"
                    name="totalQuotedEfforts"
                    value={formData.totalQuotedEfforts}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        color:
                          formData.totalQuotedEfforts === 0
                            ? "rgb(199, 198, 198)"
                            : "inherit",
                        fontFamily: "sans-serif",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Planned Effort Weeks QA"
                    name="totalQuotedEffortsQA"
                    value={formData.totalQuotedEffortsQA}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        color:
                          formData.totalQuotedEffortsQA === 0
                            ? "rgb(199, 198, 198)"
                            : "inherit",
                        fontFamily: "sans-serif",
                      },
                    }}
                  />
                </Grid>
              </>
            )}
            {(formData.departmentId === 0 || formData.departmentId === 1) &&
              (formData.projectTypeId === 2 ||
                formData.projectTypeId === 3) && (
                <Grid item xs={6}>
                  <TextField
                    label="Target Effort Weeks"
                    name="totalTargetEfforts"
                    value={formData.totalTargetEfforts}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    className="text-field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        color:
                          formData.totalTargetEfforts === 0
                            ? "rgb(199, 198, 198)"
                            : "inherit",
                        fontFamily: "sans-serif",
                      },
                    }}
                  />
                </Grid>
              )}

            {formData.departmentId === 2 &&
              (formData.projectTypeId === 2 ||
                formData.projectTypeId === 3) && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      label="Target Effort Weeks Dev"
                      name="totalTargetEfforts"
                      value={formData.totalTargetEfforts}
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      className="text-field"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        style: {
                          color:
                            formData.totalTargetEfforts === 0
                              ? "rgb(199, 198, 198)"
                              : "inherit",
                          fontFamily: "sans-serif",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Target Effort Weeks QA"
                      name="totalTargetEffortsQA"
                      value={formData.totalTargetEffortsQA}
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      className="text-field"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        style: {
                          color:
                            formData.totalTargetEffortsQA === 0
                              ? "rgb(199, 198, 198)"
                              : "inherit",
                          fontFamily: "sans-serif",
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
            <Grid item xs={6}>
              <TextField
                label="Planned Calendar Weeks"
                name="plannedCalendarEfforts"
                value={formData.plannedCalendarEfforts || 0}
                onChange={handleChange}
                fullWidth
                type="number"
                className="text-field"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color:
                      formData.plannedCalendarEfforts === 0
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>

            {/* <Grid item xs={6}>
              <Autocomplete
                options={projectAssignees}
                getOptionLabel={(option) => option.first_name}
                value={
                  projectAssignees.find(
                    (assignee) => assignee.first_name === formData.leadName
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    leadName: newValue?.first_name || "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Lead Name"
                    name="leadName"
                    fullWidth
                    className="text-field"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Lead Account ID"
                name="leadAccountId"
                value={formData.leadAccountId}
                onChange={handleChange}
                fullWidth
                className="text-field"
              />
            </Grid> */}

            <Grid item xs={6}>
              <TextField
                type="date"
                label="Start Date"
                name="startDate"
                value={
                  formData.startDate instanceof Date
                    ? formData.startDate.toISOString().split("T")[0]
                    : initialFormState.startDate || ""
                }
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color:
                      formData.startDate === null
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="date"
                label="End Date"
                name="endDate"
                value={
                  formData.endDate instanceof Date
                    ? formData.endDate.toISOString().split("T")[0]
                    : initialFormState.endDate || ""
                }
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    color:
                      formData.endDate === null
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Customer Satisfaction"
                name="customerSatisfaction"
                value={formData.customerSatisfaction || ""}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color:
                      formData.customerSatisfaction === ""
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {gradeOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Team Satisfaction"
                name="teamSatisfaction"
                value={formData.teamSatisfaction || ""}
                onChange={handleChange}
                fullWidth
                className="text-field"
                InputProps={{
                  style: {
                    color:
                      formData.teamSatisfaction === ""
                        ? "rgb(199, 198, 198)"
                        : "inherit",
                    fontFamily: "sans-serif",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {gradeOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      default:
        return "unKnown Step";
    }
  };

  return (
    <Container maxWidth="md" className="form-container">
      {isEdit && loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {showAlert && (
        <Alert
          severity={alertType}
          className="floating-alert"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setShowAlert(false)}
            >
              <IoCloseCircleOutline fontSize="inherit" />
            </IconButton>
          }
        >
          {alertMessage}
        </Alert>
      )}

      <Paper className="paper-container">
        <div className="paper-header">
          {isEdit && <h1 className="form-heading">Update Project</h1>}
          {!isEdit && <h1 className="form-heading">Create Project</h1>}
          {/* <span className="close-icon" onClick={handleCloseForm}>
            <IoCloseCircleOutline />
          </span> */}
        </div>
        <Stepper activeStep={activeStep} className="stepper-container">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Typography variant="h5" align="center">
            Thank you for your submission!
          </Typography>
        ) : (
          <>
            {renderStepContent(activeStep)}
            <div className="button-container">
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseForm}
              >
                Cancel
              </Button>
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}

              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={!isStepValid(activeStep, formData)}
                >
                  Next
                </Button>
              )}
            </div>
          </>
        )}
      </Paper>
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Project Already Exists
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to continue and update the project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)} color="primary">
            Back
          </Button>
          <Button onClick={handleContinue} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CreateProjectForm;
