
import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Checkbox, InputBase } from "@mui/material";
import { FaFilter } from "react-icons/fa";
import './index.scss';

export interface FilterOption {
  value: number; 
  label: string;
}

interface SingleSelectFilterOption {
  value: string;
  label: string;
}

interface FilterMenuProps {
  header: string;
  filterType:
    | "inHouse"
    | "hasJiraAccess"
    | "department"
    | "availabilityStatus"
    | "employmentType"
    | "project";
  selectedCount?: number;
  options: (FilterOption | SingleSelectFilterOption)[]; 
  filterStatusKey?: (string | number)[];
  selectedValue?: string | null;
  handleMenuOpen: (
    event: React.MouseEvent<HTMLButtonElement>,
    filterType: string
  ) => void;
  handleMenuClose: () => void;
  handleClearSelection: (filterType: string) => void;
  handleSelect?: (
    filterType: string,
    value: number
  ) => void;

  handleSingleSelect?: (value: string) => void;
  anchorEl: HTMLElement | null;
  setSearchProjectsText?: (value: string) => void;
  searchProjectsText?: string;
}

const FilterMenu: React.FC<FilterMenuProps> = ({
  header,
  filterType,
  selectedCount,
  options,
  filterStatusKey,
  selectedValue = null,
  handleMenuOpen,
  handleMenuClose,
  handleClearSelection,
  handleSelect,
  handleSingleSelect,
  anchorEl,
  setSearchProjectsText,
  searchProjectsText
}) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const filteredOptions = options.filter((option) => {
    const label = 'label' in option ? option.label : ''; 
    return label.toLowerCase().includes(searchProjectsText?.toLowerCase() ?? '');
  });

const handleSelectAll = () => {
    if (handleSelect && filterType === "project") {
        setSelectAllChecked(!selectAllChecked);
        if (selectAllChecked || (filterStatusKey && filterStatusKey?.length > 0) ) {
            filteredOptions.forEach(option => {
                if (filterStatusKey?.includes((option as FilterOption).value)) {
                    handleSelect(filterType, (option as FilterOption).value);
                }
            });
        } else {
            filteredOptions.forEach(option => {
                handleSelect(filterType, (option as FilterOption).value);
            });
        }
    }
};


  return (
    <>
      <IconButton
        onClick={(e) => handleMenuOpen(e, filterType)}
        style={{ paddingTop: "10px" }}
      >
        <FaFilter
          style={{
            fontSize: "13px",
            color:
              (selectedCount && selectedCount > 0) || selectedValue
                ? "orange"
                : "inherit",
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl && filterType)}
        onClose={handleMenuClose}
        style={{ maxHeight: "400px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {filterType === "project" && (
          <div className="input-basename-container">
            <InputBase
              placeholder="Search projects"
              value={searchProjectsText}
              onChange={(e) => setSearchProjectsText && setSearchProjectsText(e.target.value)}
              sx={{ width: "100%", padding: "10px" }}
            />
          </div>
        )}

        <MenuItem
          onClick={() => handleClearSelection(filterType)}
          style={{
            fontWeight:
              (filterStatusKey && filterStatusKey.length === 0) ||
              selectedValue === null
                ? "bold"
                : "normal",
            backgroundColor:
              (filterStatusKey && filterStatusKey.length === 0) ||
              selectedValue === null
                ? "#f0f0f0"
                : "white",
          }}
        >
          Clear
        </MenuItem>

        {filterType === "project" && (
          <div className="checkbox-name-container" onClick={handleSelectAll}>
            <Checkbox
            checked={
                selectedCount === filteredOptions.length && filteredOptions.length > 0
            }
            indeterminate={
                selectedCount !== undefined && selectedCount > 0 && selectedCount < options.length
            }
             />
            <p style={{ margin: 0 }}>Select All</p>
          </div>
        )}

        {filteredOptions.length > 0 ? (
          filteredOptions.map((option) => {
            const isFiltered =
              filterStatusKey?.includes((option as FilterOption | SingleSelectFilterOption).value) || selectedValue === (option as FilterOption | SingleSelectFilterOption).value;

            return (
              <div
                key={(option as FilterOption | SingleSelectFilterOption).value}
                style={{
                  fontWeight: isFiltered ? "bold" : "normal",
                  color: isFiltered ? "black" : "inherit",
                  backgroundColor: isFiltered ? "#f0f0f0" : "white",
                  paddingRight:"10px"
                }}
                onClick={() => {
                  if (handleSelect && filterStatusKey) {
                    handleSelect(filterType, (option as FilterOption).value);
                  } else {
                    if (handleSingleSelect) {
                      handleSingleSelect((option as SingleSelectFilterOption).value);
                    }
                  }
                }}
              >
                <div className="checkbox-name-container" style={{ width: filterType === "project" ? "400px" : "auto"}}>
                  {filterStatusKey && <Checkbox checked={isFiltered} />}
                  <p style={{ margin: filterStatusKey ? 0 : "10px", cursor: "pointer" }}>{(option as FilterOption).label}</p>
                </div>
              </div>
            );
          })
        ) : (
          <MenuItem>No Options Found</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default FilterMenu;
