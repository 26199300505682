export const getColor = (value?: number): string => {
    if (value === undefined || value === null) return "black";
    if (value < 50) return "red";
    if (value >= 50 && value < 80) return "#fcba03";
    return "green";
  };

  export const formatValue = (value?: number): string => {
    if (value === undefined || value === null) return "N/A";
    return `${value.toFixed(2)}%`;
  };