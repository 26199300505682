import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Snackbar, IconButton, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { useAccess } from "AccessContext";
import divamilogo from "../../assets/images/divami3.0.1.png";
import { googleAuth } from "common/api/ApiService";
import "./index.scss";
import { useGlobalDispatch } from "reducers/GlobalState";

const DashboardHeader: React.FC = () => {
  const { isLogged } = useAccess(); // Use the context
  const hasAccess = sessionStorage.getItem("hasAccess") === "true";
  const dispatch = useGlobalDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [flag, setFlag] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showAccessDeniedSnackbar, setShowAccessDeniedSnackbar] = useState(false);
  const [alert, setAlert] = useState<{ show: boolean; message: string; type: string }>({ show: false, message: "", type: "" });
  const [cookie, setCookie, removeCookie] = useCookies(["accessToken", "email", "name", "profileUrl"]);
  const [username, setUsername] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Closes the session expiration snackbar and resets the token expiration state.
  const handleCloseSnackbar = () => {
    dispatch({ type: "SET_TOKEN_EXPIRED", payload: false });
    setShowSnackbar(false);
  };

  // Extracts the username from the email by trimming and splitting it.
  const trimName = (email: string) => email.split(/[.@]/)[0].trim();

  const handleOpenLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };

  // Handles user authentication by checking token and email, sets session data and cookies accordingly.
  const handleAuthentication = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token") || cookie.accessToken;
    const email = cookie.email;
    
    if (token && email) {
      if (email.endsWith("@divami.com")) {
        setFlag(true);
        const trimmedUsername = trimName(cookie.name);
        setUsername(trimmedUsername);
        sessionStorage.setItem("authToken", token);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("username", trimmedUsername);
        setCookie("accessToken", token, { path: "/" });
        setCookie("email", email, { path: "/" });
        sessionStorage.removeItem("expired");
      } else {
        setShowAccessDeniedSnackbar(true);
        return;
      }
    }
  };

  const profilePic = cookie.profileUrl;

  // Logs the user out by removing session data and cookies and reloading the page.
  const handleLogout = () => {
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("username");
    sessionStorage.setItem("hasAccess", "false");
    removeCookie("accessToken", { path: "/" });
    removeCookie("email", { path: "/" });
    setFlag(false);
    setUsername("");
    window.location.reload();
  };

  const handleCloseAlert = () => {
    setAlert({ show: false, message: "", type: "" });
  };

  useEffect(() => {
    handleAuthentication();
  }, [location]);

  const redirectToHome = () => {
    navigate("/");
  };

  // Triggers Google authentication process.
  const handleGoogleAuth = async () => {
    try {
      await googleAuth();
    } catch (error) {
      setAlert({
        show: true,
        message: "Error while Login",
        type: "error",
      });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 5000);
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Your session has expired. You will be logged out."
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <IoCloseCircleOutline fontSize="small" />
          </IconButton>
        }
      />
      <Snackbar
        open={showAccessDeniedSnackbar}
        autoHideDuration={10000}
        onClose={() => setShowAccessDeniedSnackbar(false)}
        message="Access denied. You can only log in with a divami account."
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setShowAccessDeniedSnackbar(false)}
          >
            <IoCloseCircleOutline fontSize="small" />
          </IconButton>
        }
      />
      {alert.show && (
        <Alert
          severity={alert.type === "success" ? "success" : "error"}
          className="floating-alert"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseAlert}
            >
              <IoCloseCircleOutline fontSize="inherit" />
            </IconButton>
          }
        >
          {alert.message}
        </Alert>
      )}

      <div className="AppBar-container">
        <img className="divami-logo-image" src={divamilogo} alt="Divami Logo" onClick={redirectToHome}/>
        <div className="header-right">
          {hasAccess && (
            <div className="home-link" onClick={redirectToHome}>
              <FaHome className="home-icon" />
              <span className="home-text">Home</span>
            </div>
          )}
          {flag && (
            <img
              className="profile-pic"
              src={profilePic}
              alt="Profile"
              style={{ width: "30px", height: "30px" }}
            />
          )}
          <div className="menu-container" ref={dropdownRef}>
            <button className="iconButton-container">
              <span className="typography-username">{username}</span>
            </button>
            {flag ? (
              <button className="logout-btn" onClick={handleOpenLogoutModal}>
                Logout
              </button>
            ) : (
              <button className="login-btn" onClick={handleGoogleAuth}>
                Login
              </button>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={showLogoutModal}
        onClose={handleCloseLogoutModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleCloseLogoutModal();
              handleLogout();
            }}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DashboardHeader;
