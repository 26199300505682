import { Suspense, lazy, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./styles.scss";
import Dashboard from "pages/Dashboard";
import InternalDashboardTable from "components/InternalDashboardTable";
import UpdateProjectForm from "components/UpdateProjectForm";
import CreateProjectForm from "components/ProjectCreationForm";
import HomeScreen from "pages/HomeScreen";
import ResourceDashboardTable from "components/ResourceDashboard";
import CreateResourceForm from "components/CreateResourceForm";
import ProjectTable, { ProjectData } from "components/ProjectTable";
import NewTabTimesheets from "components/NewTabTimesheets";
import SummaryDashboard from "components/SummaryDashboard";
import { getResourceByEmailId } from "common/api/ApiService";
import DashboardHeader from "components/DashboardHeader";
import { useAccess } from "AccessContext";
import SyncRequestTable from "components/SyncRequestReport";
import SyncRequestDetailedReport from "components/SyncRequestDetailedReport";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));

export default function App() {
  const [selectedProjectId, setSelectedProjectId] = useState<string>("0");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSuccessEdit, setIsSuccessEdit] = useState<boolean>(false);
  const [selectedResourceId, setSelectedResourceId] = useState<string>("0");
  const [isResourceEdit, setIsResourceEdit] = useState<boolean>(false);
  const [isResourceSuccessEdit, setIsResourceSuccessEdit] =
    useState<boolean>(false);
  const [selectedProjectData, setSelectedProjectData] = useState<ProjectData[]>(
    []
  );
  const token = sessionStorage.getItem("authToken");
  const [syncRequestId, setSyncRequestId] = useState<number>(0);
  const { isLogged, setIsLogged } = useAccess();

  const updateTokenState = async () => {
    const token = sessionStorage.getItem("authToken");
    const email = sessionStorage.getItem("email");
    if (email && token) {
      const resp = await getResourceByEmailId(email);
      if (resp) {
        // setHasAccess(resp.specialAccess === 1);
        sessionStorage.setItem("hasAccess", resp.specialAccess === 1 ? "true" : "false");
      }
    }
    setIsLogged(!!token);
  };

  useEffect(() => {
    updateTokenState();
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "authToken") {
        updateTokenState();
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [setIsLogged]);
  
  const hasAccess = sessionStorage.getItem("hasAccess") === "true";

  return (
    <Router>
      <div className="App">
        <Suspense>
          <Routes>
            <Route path="/about" element={<About />} />
            <Route
              path="/subtimesheets"
              element={
                <NewTabTimesheets
                  selectedProjectData={selectedProjectData}
                  setSelectedProjectData={setSelectedProjectData}
                />
              }
            />
            <Route
              path="/summary-dashboard"
              element={
                hasAccess ? <SummaryDashboard /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/project-iq"
              element={
                token ? (
                  <Dashboard
                    selectedProjectData={selectedProjectData}
                    setSelectedProjectData={setSelectedProjectData}
                    hasAccess={hasAccess}
                    syncRequestId={syncRequestId}
                    setSyncRequestId={setSyncRequestId}
                  />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/project-iq/form"
              element={
                hasAccess ? (
                  <UpdateProjectForm
                    selectedProjectId={selectedProjectId}
                    setSelectedProjectId={setSelectedProjectId}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                  />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/project-iq/createform"
              element={
                hasAccess ? (
                  <CreateProjectForm
                    selectedProjectId={selectedProjectId}
                    setSelectedProjectId={setSelectedProjectId}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    isSuccessEdit={isSuccessEdit}
                    setIsSuccessEdit={setIsSuccessEdit}
                  />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/project-iq/dashboard"
              element={
                hasAccess ? (
                  <InternalDashboardTable
                    selectedProjectId={selectedProjectId}
                    setSelectedProjectId={setSelectedProjectId}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    isSuccessEdit={isSuccessEdit}
                    setIsSuccessEdit={setIsSuccessEdit}
                  />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/resource-dashboard"
              element={
                hasAccess ? (
                  <ResourceDashboardTable
                    selectedResourceId={selectedResourceId}
                    setSelectedResourceId={setSelectedResourceId}
                    isResourceEdit={isResourceEdit}
                    setIsResourceEdit={setIsResourceEdit}
                    isResourceSuccessEdit={isResourceSuccessEdit}
                    setIsResourceSuccessEdit={setIsResourceSuccessEdit}
                  />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/create-resource"
              element={
                hasAccess ? (
                  <CreateResourceForm
                    selectedResourceId={selectedResourceId}
                    setSelectedResourceId={setSelectedResourceId}
                    isResourceEdit={isResourceEdit}
                    setIsResourceEdit={setIsResourceEdit}
                    isResourceSuccessEdit={isResourceSuccessEdit}
                    setIsResourceSuccessEdit={setIsResourceSuccessEdit}
                  />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/project-iq/sync-report"
              element={<SyncRequestTable />}
            />
            <Route
              path="/project-iq/sync-report-details/:requestId"
              element={<SyncRequestDetailedReport />}
            />

            <Route path="/" element={<HomeScreen />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}
