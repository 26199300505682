import React from "react";
import { IconButton } from "@material-ui/core";
import { FaSort, FaSortAlphaUp, FaSortAlphaDown } from "react-icons/fa";
import { Resource } from "common/api/ApiService";
import './index.scss';
interface SortableHeaderProps {
  header: string;
  sortField: string | null;
  sortOrder: string;
  currentField: keyof Resource;
  onSortChange: (field: keyof Resource) => void;
//   iconStyle?: React.CSSProperties;
}

const SortableHeader: React.FC<SortableHeaderProps> = ({
  header,
  sortField,
  sortOrder,
  currentField,
  onSortChange,
//   iconStyle,
}) => {
    const iconStyle = {
        fontSize: "13px",
        color: sortOrder === "normal" ? "inherit" : sortField === currentField ? "orange" : "inherit"
    };

  return (
    <>
      <div
      className="sortable-header"
        onClick={() => onSortChange(currentField)}
      >
        {sortField === currentField ? (
          sortOrder === "asc" ? (
            <FaSortAlphaUp style={iconStyle}  />
          ) : sortOrder === "desc" ? (
            <FaSortAlphaDown style={iconStyle}  />
          ) : (
            <FaSort style={iconStyle}  />
          )
        ) : (
          <FaSort style={iconStyle}  />
        )}
      </div>
    </>
  );
};

export default SortableHeader;
